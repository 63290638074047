.order-history {
  color: $grey3;

  &__header {
    color: $grey3;
  }

  &__order-details {
    &-link {
      background-color: $secondary !important;
      display: flex;
      justify-content: center;
      align-items: center;
      @include btn-primary-small();
      &--icon {
        display: none;
      }
      &:hover {
        background-color: $primary-cta-hover !important;
        border-color: $primary-cta-hover !important;
        color: $accent2 !important;
      }
    }
  }
}

.order-details {
  color: $grey3;

  &__link {
    @include btn-primary-small();
    padding: unset;
    height: auto;
    span {
      color: $accent2;
      font-weight: 700;
    }
  }

  .shipment-summary__shipment-status-value {
    color: $secondary;
    font-weight: 700;
  }
}
