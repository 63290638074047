.search-results {
  background-color: $cultured;
  &__controls {
    .page-size-toggle {
      text-align: left;
      font-size: 13px;
      line-height: 110%;
      margin-top: 0;

      @media (min-width: $bp-large) {
        padding-left: 48px;
        font-size: 16px;
      }

      .results-count {
        font-weight: 500;
        font-size: 16px;
        &__page-sizes {
          @media screen and (min-width: $md-width) {
            padding-left: 24px;
            font-weight: 500;
            .page-size-options {
              &__item--current {
                color: rgba(62, 60, 56, 0.4);
              }
            }
          }
        }

        &__page-size {
          @media (min-width: $media-breakpoint-desktop) {
            font-size: 16px;
          }
        }
      }

      .separator {
        display: none !important;
      }

      .results-count__separator {
        &::before {
          content: '|';
        }
      }

      @include media-breakpoint-down(md) {
        .search-results__count-row__count {
          padding-left: 0;
          padding-right: 0;

          .results-count--mobile {
            font-size: 15px;
          }
        }
      }
    }

    .filters {
      &__main-toggle {
        margin-right: 0;

        .filters__toggle-section__btn {
          padding: 12px 16px;
          @include media-breakpoint-up(lg) {
            padding: 20px 0;
          }
          font-weight: 500;
          font-size: 15px;
          line-height: 110%;
          border-top: 1px solid rgba(0, 0, 0, 0.2);
          border-bottom: 1px solid rgba(0, 0, 0, 0.2);
          border-left: 1px solid rgba(0, 0, 0, 0.2);
          border-right: none;

          @media screen and (min-width: $bp-large) {
            font-size: 16px;
            border: none;
            line-height: 155%;
          }
        }
      }
    }
  }

  &__count-row {
    color: $grey3;
  }

  .results-count {
    &__page-sizes {
      .page-size-options {
        &__item {
          &--link {
            text-decoration: none;
          }
        }
      }

      &__item--current {
        color: rgba(62, 60, 56, 0.4);
      }
    }
  }
}

.sorting {
  margin-top: 0;
  &__toggle-section {

    select.sorting__toggle-section__btn {
      padding: 0 1.9375rem 0 0.4375rem;
    }

    &__btn-wrapper {
      .form-icon {
        font-size: 15px;
        &::before {
          @media (min-width: $bp-medium-max) {
            padding-left: 12px;
          }
          content: url('../../../../static/default/images/form-icon.svg');
        }
        top: calc(50% - 8px);

        @include media-breakpoint-up(lg) {
          right: 0.8rem;
        }
      }
    }
  }
}

.paged-bottom {

  .paging__page {
    @include media-breakpoint-down(md) {
      width: 2.5rem;
      height: 2.5rem;
      line-height: 2.5rem;
      align-content: center;
      font-size: 15px;
    }
  }
  .paging__page--current {
    color: $grey3;
    background-color: #faf8f5;
    border: 1.5px solid $grey3;

    @include media-breakpoint-down(md) {
      width: 2.5rem;
      height: 2.5rem;
      line-height: 2.5rem;
      align-content: center;
      font-size: 15px;
    }
  }

  .paging-status {
    .paging__status-text {
      margin-top: 4px !important;
      font-size: 13px;
      line-height: 110%;
      color: $grey3;
    }
  }
}

.paging__page--back,
.paging__page--next {
  display: none !important;
  .font-icon {
    display: inline-block;
  }
}

.breadcrumb-item {
  &:first-child {
    text-transform: lowercase;
    &::first-letter {
      text-transform: uppercase;
    }
  }
}
