.product-tile {
  &__image {
    aspect-ratio: 1 / 1; // stylelint-disable-line
    background-color: $grey1;
    padding-bottom: 0;
  }

  &__name {
    .link {
      font-weight: 700;
      color: $grey3;
    }
  }

  &__action {
    background-color: $secondary;

    &-add {
      width: 48px;
      height: 48px;
      padding: 0.6rem 0.5rem;
      border-radius: 50%;
      color: $accent2;
      left: auto;
      right: 1rem;
      bottom: 1rem;
    }

    &:hover {
      background-color: $primary-cta-hover;
    }

    &:active {
      background-color: $primary-cta-active;
    }
  }

  &.outofstock {
    .product-tile__outofstock--sticker {
      display: none;
    }

    .product-tile__action {
      display: block;
      background-color: $grey1-disabled;
      opacity: 1;
    }
  }

  &:hover {
    .product-tile__action {
      bottom: 1rem;
    }
  }

  &__wishlist--link {
    padding: 0.3rem;
    position: relative !important;
    top: 0;
    float: right;

    @include media-breakpoint-up(lg) {
      &:hover,
      &:active {
        .wishlist-icon svg path {
          fill: $secondary;
          stroke: $secondary;
        }
      }
    }
  }

  &__add-overlay-size-input:checked + &__add-overlay-size + &__add-overlay-size-btn .wishlist-icon {
    display: none;
  }

  &__rating {
    .font-icon {
      &.icon-fa-star-o {
        &.rating-star-empty {
          color: $secondary;
        }
      }
    }
  }

  &__price {
    span {
      font-weight: 700;
      font-size: 20px;
      line-height: 110%;
      letter-spacing: -0.05em;
      color: $grey3;
    }

    .start_text {
      font-size: 0.875rem;
      font-weight: 400;
      letter-spacing: normal;
    }

    .has-strike-through {
      span {
        color: #ab2328;
      }
    }

    .strike-through {
      text-decoration: none;

      .prefix {
        display: none;
      }

      span {
        font-weight: 400;
        font-size: 16px;
        text-decoration-line: line-through;
      }
    }
  }
}

.search-results-container {

  &--paging-top {

    padding-bottom: 24px !important;
    @include media-breakpoint-up(lg) {
      padding-bottom: 40px !important;
    }

    .paging-container {
      @include media-breakpoint-up(lg) {
        width: fit-content;

        &.paged-bottom {
          width: 100%;
          margin-top: 2.75rem !important;

          .paging {
            &__status-text {
              margin-top: 4px;
            }
          }
        }
      }

      .paged-bottom {
        font-weight: 500;
        font-size: 15px;
        margin-top: 2.5rem !important;
      }

      &--top {
        @media screen and (max-width: $bp-medium-max) {
          padding-right: 0;
        }
        .paging__page {

          &--current,
          &--next,
          &--back,
          &--of-last,
          &--of {
            color: rgba(62, 60, 56, 0.6);
          }

          &--next {
            .font-icon {
              top: 0;
            }
          }
        }

        .paging__pages {
          color: rgba(62, 60, 56, 0.6);
          font-weight: 400;
          font-size: 15px;

          @media (min-width: $bp-large) {
            z-index: 1;
            position: relative;
            top: 21px;
            font-size: 16px;
          }
        }
      }
    }

    .side-col--right {
      @media (min-width: $media-breakpoint-desktop) {
        max-width: 20rem;
      }

      @include media-breakpoint-up(xl) {
        max-width: 60rem;
      }
    }

    .grid-footer {
      .paged-bottom {
        .paging__page {
          color: $grey3;
          border: 1px solid $grey2;

          &--current {
            background-color: #faf8f5;
            border: 1px solid $grey3;
          }
        }
      }
    }
  }
}

.product-grid-container {
  margin-top: 1rem;
  @media (min-width: $bp-medium-max) {
    margin-top: -0.625rem;
  }
}

.product-grid {
  .product-tiles {
    @include media-breakpoint-down(sm) {
      padding: 0 0.5rem;
    }

    @include media-breakpoint-between(sm, md) {
      padding: 0 1rem;
    }

    @include media-breakpoint-only(md) {
      padding-right: 0;
    }
  }

  .product-tile {
    &__wrapper {
      @include media-breakpoint-down(xs) {
        &--odd.col-6 {
          padding-right: 0.25rem;
        }

        &--even.col-6 {
          padding-left: 0.25rem;
        }
      }
      @include media-breakpoint-only(sm) {
        &--odd.col-6 {
          padding-right: 0.5rem;
        }

        &--even.col-6 {
          padding-left: 0.5rem;
        }
      }
      @include media-breakpoint-only(md) {
        padding-right: 1rem;
      }
    }

    &__add-overlay {
      height: unset;
      padding: 24px 0;
      border-bottom: 1px solid $grey1;
      border-radius: 8px 8px 0 0;
      background-color: $white;
      width: 100%;

      @include media-breakpoint-up(sm) {
        width: 90%;
        left: 5%;
      }

      @media (min-width: $media-breakpoint-desktop) {
        width: 100%;
        left: 0;
      }

      @include media-breakpoint-up(xl) {
        width: 94%;
        left: 3%;
      }

      &-sizes {
        flex: 0 0 100%;
        max-width: unset;
        margin-left: 0;
        justify-content: center;

        &-sizes {
          display: flex;
          overflow: scroll;
          width: max-content;
          -ms-overflow-style: none;  /* Internet Explorer 10+ */
          scrollbar-width: none;  /* Firefox */

          &::-webkit-scrollbar {
            display: none;  /* Safari and Chrome */
          }
        }
      }

      &-size {
        color: $accent3;
        font-weight: 400;
        font-size: 13px;
        padding: 8px 16px;
        margin: 0.5rem 0.3rem;
        border: 1px solid $accent3;
        line-height: 110%;
        border-radius: 100px;
        width: max-content;
        margin-bottom: 3.5rem;

        &-wrapper {
          flex-basis: unset;
          width: fit-content;
        }

        &--low-stock {
          border-style: dashed;
        }

        &--disabled {
          border-color: $grey1;
          border-style: solid;
        }

        &:not(.product-tile__add-overlay-size--disabled):hover {
          background-color: transparent;
          color: $accent3;
        }

        &-input:checked {
          + .product-tile__add-overlay-size {
            background-color: transparent;
            color: $accent3;
            border: 2px solid $grey3;

            + .product-tile__add-overlay-size-btn .add-to-cart {
              @include btn-secondary-is();
              background-color: $white;
              padding: 0;

              @media (min-width: $media-breakpoint-desktop) {
                padding: 0;
              }
            }
          }
        }

        &-btn .add-to-cart {
          @include btn-secondary-is();
          background-color: $white;
          display: block;
          width: calc(100% - 2rem);
          margin: 1.5rem 1rem;
          line-height: 1;
          padding: 0;

          @media (min-width: $media-breakpoint-desktop) {
            padding: 0;
          }
        }
      }

      .add-to-wish-list {
        display: none !important;
      }

      &-close {
        display: none;
      }
    }
  }
}

.product-detail {
  &__product-carousel {
    .product {
      &-tile {
        &__price {
          span {
            font-weight: 400;
            font-size: 16px;
          }
        }
      }
    }
  }
}

.product-tile__price .link {
  display: flex;
  align-items: baseline;
}
.product-tiles {
  .from-label {
    font-size: rem-calc(20);
    font-weight: 700;
    color: #ab2328;
    margin-top: 2px;
    &.grey {
      color: $accent3;
    }
  }
}

.search-results {
  .tab-content {
    @include media-breakpoint-down(sm) {
      padding: 0 1rem;
    }
    @media (min-width: $bp-medium) and (max-width: $bp-medium-max) {
      padding: 0 2.5rem;
    }
    @media (min-width: $bp-large) and (max-width: $max-width-xl) {
      padding: 0 3.5rem;
    }
    @media (min-width: $bp-xl-min) {
      padding: 0 4.5rem;
    }

    .filters-container {
      @include media-breakpoint-up(lg) {
        margin-right: 2.5rem;
      }
    }
  }
}
