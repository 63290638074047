.hero-product {
  max-width: 90%;
  color: $accent3;
  padding: 0 14px;

  picture {
    height: 100%;
    width: 100%;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      max-height: 528px;
    }
  }

  .hero-text-container {
    position: absolute;
    bottom: 0;
    padding: 3rem;

    h2 {
      text-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
    }

    @include media-breakpoint-down(xs) {
      padding: 2rem;
      p {
        max-height: 7.8rem;
        overflow: hidden;
      }
    }
  }

  .hero-product-container {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    button {
      @include btn-primary-small();
    }

    img {
      min-height: 325px;
      filter: brightness(0.9);
    }
  }

  .hero-product-img-container {
    width: 144px;
    height: 160px;
    background: $accent2;

    img {
      height: 160px;
      width: 144px;
    }
  }

  .heroProductButtonLink {
    color: $accent3;
    text-decoration: underline;
    margin-top: 10px;
  }
}
