.login {
  &__tab-items,
  &__tab-panes {
    @include media-breakpoint-up(lg) {
      @include make-col(8);
    }

    @include media-breakpoint-up(xl) {
      @include make-col(6);
    }
  }

  &__tab-link__text {
    color: $accent3;
  }

  &__form {
    padding-bottom: 2.5rem;

    &__heading {
      color: $accent3;
    }

    input,
    select {
      color: $accent3;
      border-color: $accent3;

      ::placeholder {
        color: $accent3;
      }
    }

    .login__disclaimer {
      color: $accent3;
      font-size: 1rem;
      text-align: center;
    }

    a {
      color: $accent3;
    }

    label {
      color: $accent3;
    }

    button {
      @include btn-primary-small();
    }
  }
}

.registration {
  .form-title,
  .contact-preferences-title,
  .registration__intro {
    color: $accent3;
    text-align: left;
  }

  &__form {
    &__heading {
      color: $accent3;
    }

    input {
      color: $accent3;

      ::placeholder {
        color: $accent3;
      }
    }

    .registration__disclaimer {
      color: $accent3;
      font-size: 1rem;
    }

    a {
      color: $accent3;
    }

    label {
      color: $accent3;
    }

    button {
      @include btn-primary-small();
    }
  }

  .newsletter-form__optin__wrapper {
    .custom-control-label {
      font-weight: 700;
      line-height: 110%;
      color: $accent3;
    }
  }
}
