.shipment-summary {
  &__shipment {
    border: rem-calc(1) solid $border-color;
  }

  &__shipment-header {
    border-bottom: rem-calc(1) solid $border-color;
  }

  &__shipment-status {
    @include media-breakpoint-up(lg) {
      text-align: right;
    }
  }

  &__shipment-status-label {
    font-weight: bold;
  }

  &__shipment-tracking {
    a {
      height: auto;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
