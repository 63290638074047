.wishlist {
  .product-info {
    @include media-breakpoint-up(md) {
      @include make-col(4);
    }
  }

  &__indicator {

    @media screen and (min-width: $bp-small-max) and (max-width: $bp-medium-max) {
      right: 12px;
      top: 70px;
    }

    @include media-breakpoint-up(lg) {
      right: 30px;
      top: 70px;
    }

    @include media-breakpoint-up(xl) {
      right: -26px;
    }
  }

  button,
  .btn {
    &.btn-secondary {
      @include btn-primary-is();
    }
    .icon-cross {
      font-size: 1rem;
    }
  }

  &__send-email,
  &__send-product-email {
    a {
      @include btn-secondary-is();
      text-decoration: none;
    }

    .font-icon {
      display: none;
    }
  }

  &__send-product-email {
    margin-top: 1rem;
    a {
      display: block;
    }
  }
}
