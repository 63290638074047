.cart-page {
  .cart-header {
    margin: 3.5rem 0 2.5rem !important;
  }

  .mobile-checkout-btn-ctr {
    display: none;
  }

  *:not(u) {
    color: $accent3;
  }

  .coupon-code-field {
    height: 39px;

    @include media-breakpoint-up(lg) {
      height: 44px;
    }
  }

  .text-danger {
    span {
      color: #c00;
    }
  }

  u {
    color: $grey3;
    text-decoration: none;
  }

  h5 {
    font-size: 25px;
    font-weight: 700;
  }

  .totals {
    .order-totals {
      font-size: 20px;
      font-weight: 700;
    }

    &__summary {
      margin: 2.5rem 0 2rem !important;

      &__title {
        padding-bottom: 1rem;
      }
    }
  }

  .checkout-btn {
    @include btn-primary-small();
    display: inline-flex;

    text-align: left;

    img {
      flex: 0 0 5%;
      position: relative;
      height: 24.27px;
      aspect-ratio: 1 / 1; // stylelint-disable-line
      @include media-breakpoint-up(xl) {
        max-width: 20px;
        height: 20px;
      }
      @include media-breakpoint-down(lg) {
        max-width: 16px;
        height: 16px;
      }
    }

    div {
      flex: 0 0 95%;
      text-align: center;
      color: $accent2;
      text-transform: lowercase;

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }

  .cart-item-wrapper {
    border-top: 1px solid #dee2e6;
    padding-top: 10px;
  }

  .cart-item {
    .line-item-quantity {
      &__quantity-selector {
        padding-left: 3rem;
      }

      .form-group {
        margin-bottom: rem-calc(5);

        label {
          font-size: $fontsize-medium;
          font-weight: 700;
          top: rem-calc(10);
        }

        select {
          font-size: $fontsize-medium;
          font-weight: 700;
          padding-top: 0.45rem;
        }
      }
    }
  }

  .product-card {
    &__modify-overlay {
      background-color: #dee2e6;
    }
  }

  @include media-breakpoint-down(md) {
    .cart-item-bottom-section {
      padding-left: 0 !important;
      margin-left: 0 !important;
      margin-top: unset;

      .product-card__content_qty {
        padding-left: 0;
        padding-right: 0;

        .line-item-quantity {
          max-width: none;
        }
      }

      .product-card__modifier--wishlist {
        margin-left: 0;
      }
    }
  }

  .cart-text-link {
    font-weight: 500;
    color: $grey3;
    display: inline-flex;
    flex-direction: column;
    gap: 2px;
    font-size: 15px;

    @include media-breakpoint-up(lg) {
      font-size: 16px;
    }

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 1.5px;
      background-color: $grey3;
      margin-top: 0.1rem;
    }

    &:active {
      color: $secondary;

      &::after {
        background-color: $secondary;
      }
    }

    @include media-breakpoint-up(xl) {
      &:hover {
        color: $secondary;

        &::after {
          background-color: $secondary;
        }
      }
    }
  }

  .cart-optin__submit {
    display: block;
  }

  .checkout-and-applepay {
    .dw-apple-pay-button.apple-pay-cart,
    .dw-apple-pay-button.apple-pay-cart:hover {
      margin-top: 0;
      padding: 10px;
      border-radius: 6px;
    }
    .apple-pay-express-checkout {
      margin-left: -8px;
      margin-right: -8px;

      .apple-pay-cart-consents {
        line-height: 24.8px;
        color: $grey3;
      }
    }
    .cart-express {
      padding-left: 8px;
      padding-right: 8px;
    }
  }

  .cart-input-checkbox {

    ~ .checkbox-tick {
      border: 1.2px solid $accent3;
    }

    &:checked ~ .checkbox-tick {
      &::before {
        width: 6px;
        border: solid $grey3;
        border-width: 0 2px 2px 0;
      }
    }
  }
}

.cart-container {
  .cart-empty {
    .continue-shopping-link {
      @include btn-primary-is();
    }
  }
}

.cart-left {
  .apple-pay-express-checkout {
    display: none !important;
  }
}

.dw-apple-pay-button:not(.apple-pay-cart) {
  display: none;
}
