.checkout-shipping {
  &__form {
    margin-top: 1.5rem;

    @include media-breakpoint-down(sm) {
      fieldset.inset-legend {
        padding: 0.5rem 2rem;
      }
    }
  }

  &__types {
    display: none;
  }

  &__address {
    &-title {
      text-transform: capitalize;
      text-align: center;
    }
  }

  .saved-addresses__saved-toggle {
    font-weight: 700;
  }

  &__gift-packaging-title,
  &__packaging-title {
    font-weight: 700;
    line-height: 110%;
    letter-spacing: -0.05em;
    font-size: 20px;
  }

  &__method-name {
    font-size: 16px;
    line-height: 110%;
  }
}

.saved-addresses {
  &__new-btn-container .btn {
    @include btn-secondary-is();
    width: 98%;
    margin: 0 1%;
  }
}
