.edit-profile {
  &__form-outer-wrapper {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }
  &__form-wrapper {
    max-width: rem-calc(450);
    color: $accent3;

    .form-group {
      width: 100%;
      padding: 0;
    }

    input,
    select {
      color: $accent3;
      border-color: $accent3;

      ::placeholder {
        color: $accent3;
      }
    }

    .tracking-consent {
      color: $black;
    }

    .btn {
      &.btn-secondary {
        @include btn-primary-is();
      }
    }
  }

  .change-password-disclaimer {
    font-size: 13px;
    font-weight: 700;
  }
}
