.hero-container {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .hero-img {
    object-fit: cover;
    width: 100%;
    max-height: 554px;
    filter: brightness(0.9);
    @include media-breakpoint-down(xs) {
      min-height: 650px;
    }
  }

  h1 {
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
  }

  button {
    @include btn-primary-small();
  }

  .hero-carousel-container {

    .hero-carousel {
      padding: 16px 0;
      background: rgba(255, 255, 255, 0.4);

      .glide__slide {
        max-width: 200px;
        min-width: 200px;
      }

      .category-title {
        margin: 0;
        padding-top: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $accent2;
      }

      .pad15 {
        text-align: center;
        background: $accent2;
        color: $black;
        height: 200px;
      }

      .glide__arrows {
        .glide__arrow {
          color: $accent3;
          padding: 20px;
          background: $white;
          border-radius: 0;
          border: 1px solid $white;
          background-repeat: no-repeat;
          background-position: center;

          @include media-breakpoint-down(xs) {
            display: none;
          }

          &:hover {
            color: $accent3;
            background-color: $grey1;
            border: 1px solid $grey2;
            opacity: 1;
          }

          &:active {
            background-color: $grey3;
            border: 1px solid $black;
            color: $white;
          }
        }

        .glide__arrow--left {
          left: 0;
          background-image: url('../../../../../static/default/images/icons/prev.svg');
        }

        .glide__arrow--right {
          right: 0;
          background-image: url('../../../../../static/default/images/icons/next.svg');
        }

        .glide__arrow--disabled {
          opacity: 0 !important;
        }
      }
    }
  }
}
