.track-order {
  @include make-container();
  @include make-container-max-widths();

  .trackorder-fields {
    @include media-breakpoint-up(md) {
      @include make-col(8);
      @include make-col-offset(2);
    }

    @include media-breakpoint-up(lg) {
      @include make-col(4);
      @include make-col-offset(4);
    }

    input,
    select {
      color: $accent3;
      border-color: $accent3;

      ::placeholder {
        color: $accent3;
      }
    }

    button {
      @include btn-primary-small();
    }
  }

  &-header {
    color: $accent3;

    &-text {
      color: $accent3;
    }
  }
}
