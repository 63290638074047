@import "lightgallery/scss/lightgallery-bundle";
@import "lightgallery/scss/lightgallery";
@import "mathsass/dist/math";
@import '../components/productImages';
@import "../components/imageGallery";
@import '../components/flyout';

$color-swatch-size: rem-calc(32) !default;
$color-swatch-border-radius: 50% !default;
$color-selected-swatch-padding: rem-calc(3);
$flyout-style-headings: false !default;
$product-content-padding: rem-calc(10);
$product-size-attribute-height: rem-calc(45);
$product-add-to-wishlist-size: $button-height;
$product-add-to-wishlist-offset: rem-calc(5);
$product-add-to-wishlist-icon-size: rem-calc(24);
$product-delivery-pane-max-width: 90% !default;
$product-delivery-pane-max-width-desktop: rem-calc(600) !default;
$product-stock-check-pane-max-width: 90% !default;
$product-stock-check-pane-max-width-desktop: rem-calc(754) !default;
$product-stock-check-result-min-height: rem-calc(530) !default;
$product-stock-check-color-swatch-size: rem-calc(22) !default;
$product-stock-check-color-swatch-offset: rem-calc(4) !default;
$product-stock-check-product-col-width-desktop: rem-calc(420) !default;
$product-stock-check-color-result-details-offset: rem-calc(20) !default;
$product-stock-check-color-result-details-arrow-size: rem-calc(20) !default;
$product-stock-check-color-result-stores-width: $product-stock-check-product-col-width-desktop - $product-stock-check-color-result-details-offset !default;
$product-reviews-pane-max-width: 90% !default;
$product-reviews-pane-max-width-desktop: rem-calc(750) !default;
$product-size-chart-pane-max-width: 90% !default;
$product-size-chart-pane-max-width-desktop: rem-calc(440) !default;
$product-content-details-width: rem-calc(420) !default;
$continuity-flyout-pane-max-width: 90% !default;
$continuity-flyout-pane-max-width-desktop: rem-calc(440) !default;
$mobile-m-width: 375px;
$mobile-max-width: 768px;
$mobile-min-width: 501px;

@include media-breakpoint-down(md) {
  .page {
    &[data-action="Product-Show"],
    &[data-action="Product-ShowInCategory"] {
      .header-items .search {
        display: none;
      }
    }
  }
}

.product-detail {
  // Allow flyouts to cover the footer
  position: relative;
  z-index: $product-detail-z-index;

  // Allow flyouts to cover columns that are after them in the DOM

  &.flyout-open & {
    &__product-bottom {
      position: relative;
      z-index: $product-detail-bottom;
    }
  }

  @include media-breakpoint-down(lg) {
    max-width: 100%;
  }

  &__sticker {
    span {
      position: absolute;
      bottom: rem-calc(50);
      padding: rem-calc(5) rem-calc(10);
    }
  }

  .product-breadcrumb {
    ol {
      justify-content: flex-start;

      li,
      a {
        color: $black;
      }
    }
  }

  .mobile-breadcrumb-arrow {
    font-size: $fontsize-medium;
  }

  &__nav-row {
    align-items: center;
    justify-content: center;

    &__previous {
      border-right: solid rem-calc(1) $border-color;
      padding: map-get($spacers, 2);
    }

    &__previous,
    &__next {
      text-align: center;
    }

    @include media-breakpoint-up(md) {
      &__nav {
        padding-left: $product-content-padding;
      }
    }
    @include media-breakpoint-up(lg) {
      &__breadcrumbs {
        padding-left: 2 * $product-content-padding;
      }

      &__nav {
        padding-right: 2 * $product-content-padding;
      }
    }
    @include media-breakpoint-up(xl) {
      &__nav {
        padding-left: 0;
        padding-right: 0;
      }
    }

    &__nav {
      min-height: rem-calc(35);
      line-height: rem-calc(35 / 2);

      @include media-breakpoint-up(lg) {
        max-width: $product-content-details-width + $product-content-padding;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &__row {
      border: rem-calc(1) solid $border-color;
      border-top: 0;

      @include media-breakpoint-up(lg) {
        border: 0;
      }
    }

    &__category {
      border-right: rem-calc(1) solid $border-color;
      text-align: center;
      padding: map-get($spacers, 2);
    }
  }

  &__product-images {
    .image-nav-arrow {
      font-size: rem-calc(25);
      color: $text-dark;
    }

    .carousel-indicators {
      bottom: 0;

      li {
        width: rem-calc(10);
        height: rem-calc(10);
        border-radius: 50%;
      }
    }
    [data-swipe-disabled="true"] {
      .carousel-controls,
      .carousel-indicators {
        display: none;
      }
    }

    .primary-images {
      .pinch-zoom-container {
        height: auto !important; // Overrides incorrectly calculated heights set by the pinch zoom library

        img {
          position: relative !important; // Overrides position: absolute set by the pinch zoom library

          // The library injects a clone of the image for some calculations at the end of each zoom action;
          // However, the position change above makes it overlap the original image for a split second;
          // Hiding it doesn't seem to break the functionality and makes for a smoother behaviour
          + img {
            display: none;
          }
        }
      }

      &__wrapper {
        @include media-breakpoint-up(lg) {
          padding-left: $product-content-padding;
        }

        .primary-images__video-wrapper {
          .primary-images__video-wrapper__inner {
            background-color: transparent;
          }
        }
      }

      &__image-wrapper {
        cursor: default;
        background-clip: content-box;

        &__inner {
          @include product-image-container();
          overflow: hidden;

          .play-button {
            background-image: url('../../../../static/default/images/playButton.png');
            position: absolute;
            bottom: calc(50% - 28px);
            width: 56px;
            height: 56px;
            left: calc(50% - 28px);
            z-index: 3;
          }

          .pause-button {
            background-image: url('../../../../static/default/images/pauseBtn.png');
            position: absolute;
            bottom: calc(50% - 28px);
            width: 56px;
            height: 56px;
            left: calc(50% - 28px);
            z-index: 2;
          }

          #product-video {
            pointer-events: none;
          }
        }

        @include media-breakpoint-up(lg) {
          &--padded {
            padding: $product-content-padding;
          }
        }

        &[href] {
          .primary-images__image {
            user-select: none;
            cursor: zoom-in;
          }
        }
      }

      &__carousel {
        .primary-images__video-link-button {
          position: absolute;
          padding: 10px 0;
          width: 100%;
          height: 40px;
          background: #e0e0e0;
          bottom: -2.5rem;
          z-index: 2;

          .primary-images__video-link {
            width: 100%;
            line-height: 20px;
            text-align: center;
          }
        }
      }

      &.primary-video {
        margin-bottom: 3rem;
      }

      .carousel-control-prev,
      .carousel-control-next {
        height: 20rem;
        top: calc(50% - 10rem);
      }
    }
  }

  &__details-section {
    padding: $product-content-padding;

    @include media-breakpoint-up(lg) {
      padding-right: 2 * $product-content-padding;
    }

    &--quickview {
      @include media-breakpoint-up(lg) {
        padding-top: 0;
      }
    }

    &--set {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @include media-breakpoint-up(lg) {
    &__details-row {
      position: relative;
    }

    &__details-section {
      padding-top: rem-calc(33);

      &-sticky-container {
        max-width: $product-content-details-width;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
      }

      &-sticky {
        max-width: $product-content-details-width;
        width: 100%;
      }
    }
  }

  &__brand-name {
    font-size: $fontsize-xmedium;
    letter-spacing: rem-calc(0.8);
  }

  &__product-name {
    font-size: $fontsize-xxxlarge;
    letter-spacing: rem-calc(0.7);
    color: $primary;
  }

  &__prices {
    span {
      font-size: $fontsize-xxlarge;
      letter-spacing: rem-calc(0.7);

      &.prefix {
        display: none;
      }
    }

    .strike-through {
      color: $black;
      text-decoration: line-through;
    }

    .has-strike-through {
      color: $lipstick;
    }
  }

  &__ratings {
    padding-left: 0;
    padding-right: 0;
    margin-top: map-get($spacers, 2);
    font-size: $fontsize-medium;

    .font-icon {
      font-size: $fontsize-medium;
    }
  }

  &__attribute {
    $attrRoot: &;

    &__display-name {
      text-transform: capitalize;
      color: $grey1;
    }

    &__values-row {
      margin-left: rem-calc(-5);
    }

    &__value-wrapper {
      padding-left: map-get($spacers, 1);
      padding-right: map-get($spacers, 1);
      margin-bottom: map-get($spacers, 2);
    }

    &__value {
      cursor: pointer;
    }

    &--color & {
      &__value {
        @include product-swatch();

        width: $color-swatch-size + (2 * $color-selected-swatch-padding);
        height: $color-swatch-size + (2 * $color-selected-swatch-padding);
        padding: $color-selected-swatch-padding;

        &--current {
          border: rem-calc(1) solid $primary !important;
          padding: $color-selected-swatch-padding - rem-calc($border-width);
        }

        &--unselectable {
          opacity: 0.5;
        }

        &__icon {
          border-radius: $color-swatch-border-radius;
          width: $color-swatch-size;
          height: $color-swatch-size;
        }
      }
    }

    &--size & {
      &__value-wrapper {
        @include make-col(3);
        @include make-col-ready();

        @include media-breakpoint-up(md) {
          @include make-col(2);
        }
        @include media-breakpoint-up(lg) {
          @include make-col(1.5);
        }

        height: $product-size-attribute-height;
        line-height: $product-size-attribute-height;
        padding-left: map-get($spacers, 1);
        padding-right: map-get($spacers, 1);

        text-align: center;
        font-weight: bold;

        &--one-size:only-child {
          flex: 0 0 auto;
          max-width: none;
          width: auto;
          a {
            padding: 0 rem-calc(5);
          }
        }

        .tippy-popper {
          max-width: rem-calc(240);
        }
      }

      &__value {
        display: block;
        width: 100%;
        height: 100%;
        border: 1px solid $border-color;

        &--current {
          border-color: $primary;
        }

        &--low-stock {
          position: relative;
          background-image: url('../../../../static/default/images/size-lowstock.svg');
          background-size: 100% 100%;
        }

        &--preorder {
          position: relative;
          background-image: url('../../../../static/default/images/size-preorder.svg');
          background-size: 100% 100%;
        }

        &--continuity {
          position: relative;
          background-image: url('../../../../static/default/images/size-unavailable.svg');
          background-size: 100% 100%;
        }

        &--unselectable {
          position: relative;
          border-color: $border-color; // Override the --current style
          background-image: url('../../../../static/default/images/size-unavailable.svg');
          background-size: 100% 100%;
          color: $border-color;

          &:focus {
            outline: 0;
          }
        }

        &--unselectable #{$attrRoot}__value__text {
          position: relative;
          background-color: $page-bg;
          z-index: $product-detail-attribute-unselectable-z-index;
        }
      }
    }

    &__error {
      color: $red;
    }

    &--error & {
      &__value {
        border-color: $danger;
      }
    }

    &__fitVariants__container {
      margin-bottom: 0.5rem;
    }
  }

  .size-chart {
    text-decoration: underline;
    font-weight: bold;
  }

  &__add-to-cart {
    .cart-and-ipay {
      display: flex;
    }

    .add-to-cart {
      width: calc(100% - #{$product-add-to-wishlist-size + $product-add-to-wishlist-offset});

      &.btn-secondary:disabled {
        background-color: $btn-disabled-background-color;
        border-color: $btn-disabled-background-color;
        color: $black;
      }
    }

    .continuity-flyout {
      width: 100%;

      &__button {
        width: 100%;
        white-space: normal;
        height: auto;

        &.disabled {
          border: rem-calc(1px) solid transparent;
        }
      }

      .mobile-message {
        display: none;
      }
    }

    .add-to-wish-list {
      width: $product-add-to-wishlist-size;
      height: $product-add-to-wishlist-size;
      margin-left: $product-add-to-wishlist-offset;
      padding: 0;

      .wishlist-icon {
        svg {
          width: $product-add-to-wishlist-icon-size;
          height: $product-add-to-wishlist-icon-size;

          path {
            fill: $white;
          }
        }

        &--active {
          display: none;
        }
      }

      &[disabled] {
        background-color: $white !important;

        .wishlist-icon {
          svg {
            path {
              fill: $secondary;
            }
          }

          &--inactive {
            display: none;
          }
          &--active {
            display: inline;
          }
        }
      }
      &.active {
        background-color: $white !important;
        .wishlist-icon {
          svg {
            path {
              fill: $secondary;
            }
          }
          &--inactive {
            display: none;
          }
          &--active {
            display: inline;
          }
        }
      }
    }

    &__notification {
      background-color: $grey3;
      font-size: $fontsize-large;
      text-align: center;
    }
  }

  &__add-to-wishlist {
    &__notification {
      display: none;
    }
  }

  &__stock-check {
    @include flyout($product-stock-check-pane-max-width, $product-stock-check-pane-max-width-desktop, $flyout-style-headings);

    &__container {
      padding: map-get($spacers, 3);
    }

    &__heading {
      text-align: center;
      margin-top: map-get($spacers, 4);
      margin-bottom: map-get($spacers, 5);
    }

    &__link {
      display: block;
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
      width: calc(100% - #{$product-add-to-wishlist-size + $product-add-to-wishlist-offset});

      &:hover {
        text-decoration: underline;
      }
      &__icon {
        font-size: rem-calc(24);
      }
      &__text {
        font-size: $fontsize-large;
      }
    }

    &__form {
      &__image {
        width: rem-calc(85);
        height: auto;
      }

      &__fields {
        position: relative;

        &__background {
          display: inline-block;
          width: $product-stock-check-color-swatch-size;
          height: $product-stock-check-color-swatch-size;
          position: absolute;
          top: $product-stock-check-color-swatch-offset;
          left: $product-stock-check-color-swatch-offset;
          z-index: 1;
          border-radius: 50%;
        }
      }

      &__attribute-container {
        .form-icon {
          margin-top: map-get($spacers, 1);
        }
      }

      &__attribute {
        &--color {
          .has-float-label & {
            padding-left: $product-stock-check-color-swatch-size + (2 * $product-stock-check-color-swatch-offset);
          }
        }
      }

      &__store {
        margin-top: map-get($spacers, 4);

        .btn-storelocator-search {
          @include btn-secondary();
        }

        .detect-location-text {
          display: none;
          outline: none;
          cursor: pointer;
          background: none;
          border: none;
        }

        @include media-breakpoint-up(lg) {
          max-width: calc(100% - #{$product-stock-check-product-col-width-desktop});
          position: relative;
          margin-top: map-get($spacers, 3);
        }
      }
    }

    &__result {
      position: relative;
      min-height: $product-stock-check-result-min-height;

      &__row {
        width: 100%;
        cursor: pointer;
        position: relative;
        padding-top: map-get($spacers, 4);
        padding-bottom: map-get($spacers, 4);

        @include media-breakpoint-up(lg) {
          width: $product-stock-check-color-result-stores-width;
        }

        &--sizes {
          padding: map-get($spacers, 4) 0;
        }
      }

      &__store-details-arrow {
        display: none;
        width: $product-stock-check-color-result-details-arrow-size;
        height: $product-stock-check-color-result-details-arrow-size;
        border-top: rem-calc(1) solid $border-color;
        border-left: rem-calc(1) solid $border-color;
        transform: rotate(-45deg);
        position: absolute;
        top: calc(50% - #{$product-stock-check-color-result-details-arrow-size / 2});
        right: -1.5 * $product-stock-check-color-result-details-arrow-size;
        z-index: 1;
        background-color: $white;
      }

      &__store-details {
        margin: map-get($spacers, 3) 0;
        display: none;
        width: 100%;
        position: absolute;
        top: 100%;
        left: 0;
        border: rem-calc(1) solid $border-color;

        @include media-breakpoint-up(lg) {
          top: 0;
          left: $product-stock-check-color-result-stores-width + $product-stock-check-color-result-details-offset;
          width: calc(100% - #{$product-stock-check-color-result-stores-width + $product-stock-check-color-result-details-offset});
        }

        &__phone {
          &__icon {
            font-size: rem-calc(25);
          }

          &__text {
            font-size: $fontsize-large;
          }
        }

        &__distance {
          color: $grey1;
        }

        &__worktimes {
          .current-day {
            color: $secondary;
            font-weight: bold;
          }
        }
      }

      &__store-details-toggle:checked + &__row {
        background-color: $grey3;
      }

      &__store-details-toggle:checked + &__row + &__store-details {
        display: block;
      }

      &__nearest-stocked-check {
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        right: 0;
      }

      @include media-breakpoint-up(lg) {
        margin-top: map-get($spacers, 5);

        &__store-details-toggle:checked + &__row &__store-details-arrow {
          display: block;
        }
      }
    }
  }

  &__delivery {
    @include flyout($product-delivery-pane-max-width, $product-delivery-pane-max-width-desktop, $flyout-style-headings);
  }

  &__accordion {
    .card,
    .card-header {
      border: none;
      &.border-bottom {
        margin-bottom: 0.1px;
      }
    }

    .card-body {
      padding: map-get($spacers, 1) map-get($spacers, 5) map-get($spacers, 3) 0;

      ul {
        margin-top: rem-calc(26);
        line-height: 1.9;
      }
    }

    &-heading {
      .btn {
        text-decoration: none;
        text-transform: capitalize;

        i {
          top: 50%;
          transform: translateY(-50%);
          right: rem-calc(10);
        }

        &[aria-expanded="true"] {
          .close-icon {
            display: block;
          }

          .open-icon {
            display: none;
          }
        }

        &[aria-expanded="false"] {
          .close-icon {
            display: none;
          }

          .open-icon {
            display: block;
          }
        }
      }
    }

    &__product-details-delivery-returns {
      margin-bottom: 0;

      tr {
        background-color: $WhiteChocolate !important;
      }
    }
  }

  &__social-icons {
    text-align: center;
    padding: map-get($spacers, 2);
    margin-top: map-get($spacers, 2);
    margin-bottom: map-get($spacers, 2);

    &__callout {
      display: none;
    }

    li {
      display: inline-block;
    }

    i {
      font-size: rem-calc(21);
      color: $grey4;
      width: rem-calc(18);
      height: rem-calc(18);
    }
  }

  &__product-bottom {
    padding-left: 0;
    padding-right: $product-content-padding;

    @include media-breakpoint-up(md) {
      padding-left: $product-content-padding;
    }

    @include media-breakpoint-up(lg) {
      padding-left: 2 * $product-content-padding;
    }
  }

  &__content-container {
    padding-left: $product-content-padding;
    padding-right: 0;

    @include media-breakpoint-up(md) {
      padding-left: 0;
    }
  }

  &__bottom-recommendations {
    &__tab {
      position: relative;
      top: 1px;
      background-color: $page-bg;
    }

    &__tab-link {
      position: relative;
      font-size: $fontsize-xlarge;
      border-bottom: rem-calc(1) solid $border-color;

      &::before {
        content: '';
        display: block;
        width: 100%;
        height: rem-calc(5);
        position: absolute;
        top: 0;
        left: 0;
      }

      &.active {
        border-bottom: none;
        border-right: rem-calc(1) solid $border-color;

        &::before {
          background-color: $secondary;
        }
      }
    }

    @include media-breakpoint-down(md) {
      .tab-content > &__tab-pane {
        display: block;
        opacity: 1;
      }
    }
  }

  .product-tile__body {
    padding-left: 0;
    padding-right: map-get($spacers, 2);
  }

  @include media-breakpoint-down(lg) {
    &__tile-row-container {
      overflow: hidden;
      margin-left: $product-content-padding;
    }

    &__tile-row {
      flex-wrap: nowrap;
      overflow-x: auto;

      &__item {
        flex: 0 0 auto;
        max-width: rem-calc(225);
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &__tile-row {
      &__item {
        @for $i from 1 through 12 {
          &--#{$i} {
            max-width: percentage(1 / $i);
            flex: auto;
          }
        }
      }
    }
  }

  .product-tile {
    &__swatches,
    &:hover .quickview,
    .quickview {
      display: none;
    }
  }

  &__rating {
    .rating-star {
      font-size: $fontsize-medium;
    }

    @include flyout($product-reviews-pane-max-width, $product-reviews-pane-max-width-desktop, $flyout-style-headings);

    &__review {
      border-bottom: rem-calc(1) solid $grey3;

      &-score {
        color: $secondary;
        text-align: center;
      }
    }

    &__heading {
      text-align: center;
      padding-top: map-get($spacers, 3);
    }

    &__score {
      color: $secondary;
      text-align: center;
    }
    &__status {
      &--sort {
        margin-bottom: map-get($spacers, 3);
      }
    }
    &__reviews-sort-label {
      @include media-breakpoint-up(sm) {
        display: inline-block;
      }
    }
  }

  &__size-chart {
    @include flyout($product-size-chart-pane-max-width, $product-size-chart-pane-max-width-desktop, $flyout-style-headings);

    .size-guide-link {
      padding-right: map-get($spacers, 2);
    }

    &__close-label {
      display: none;
    }

    &__container {
      padding: map-get($spacers, 3);
      padding-bottom: map-get($spacers, 4);
    }

    &__content {
      font-size: $fontsize-large;

      .sizes-content-table {
        thead {
          tr {
            background-color: $grey3;
          }
        }
        tbody {
          tr:nth-child(even) {
            background-color: $grey3;
          }
        }
      }
    }
  }

  &__item-link {
    padding: 0 map-get($spacers, 3) map-get($spacers, 3);

    &__text {
      text-align: center;
    }
  }

  &--set & {
    @include media-breakpoint-up(lg) {
      &__set-image {
        max-width: rem-calc(500);
      }

      &__set-heading,
      &__set-sub-heading {
        text-align: center;
      }
    }
  }

  &__recently-viewed {
    h5 {
      font-size: $fontsize-xlarge;
      text-transform: capitalize;
    }
  }

  &__full-pdp-link {
    display: none;
  }

  &__pre-order-message {
    padding: 3px 18px 4px 4px;
    width: fit-content;
    background-color: $WhiteChocolate;
  }
}

.continuity-flyout {
  @include flyout($continuity-flyout-pane-max-width, $continuity-flyout-pane-max-width-desktop);

  &__close-icon {
    padding-top: map-get($spacers, 2);
    padding-right: map-get($spacers, 2);
  }

  &__add-to-wishlist {
    display: none;
  }

  &__container {
    padding: map-get($spacers, 3);
    position: absolute;
  }

  &__header {
    display: block;
    overflow: hidden;
  }

  &__title {
    float: left;
  }
}

.category-homewares {
  .product-detail__attribute__value__text {
    @media only screen and (max-width: $mobile-m-width) {
      padding: 0 !important;
    }
  }
}

.fit-rating-scale {
  height: 4px;
  width: 100%;
  background: #e0e0e0;
  border-radius: 2px;
  align-self: stretch;
  flex-grow: 0;
  z-index: 1;
}

.fit-labels {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 15px;
  align-self: stretch;
  flex-grow: 0;
  z-index: 2;
  padding: 20px 0;
}

.fits-small {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.1em;
  color: #000;
  order: 0;
  flex-grow: 1;
}

.prefect-fit {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #000;
  order: 1;
  flex-grow: 1;
}

.fits-large {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  letter-spacing: 0.1em;
  color: #000;
  order: 2;
  flex-grow: 1;
}

.customer-fit-rating {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  margin-top: 2rem;

  .customer-fit-rating-label {
    font-weight: 600;
    font-size: 14px;
    color: #333;
    order: 0;
    flex-grow: 0;
  }

  .customer-fit-reviews-count {
    font-weight: 400;
    font-size: 12px;
    text-align: right;
    order: 1;
    flex-grow: 1;

    .product-detail__ratings-link {
      text-decoration: underline;
      color: #999;
    }
  }
}

.fit-rating-marker {
  padding: 0 5%;

  #fit-marker {
    position: relative;
    top: 0.75rem;
    height: 10px;
    width: 10px;
    background-color: #000;
    border-radius: 50%;
    display: inline-block;
  }
}

.stickyBarMenu {
  position: fixed;
  bottom: 0;
  right: 0;
  transform: translateY(100%);
  transition: transform 0.1s linear;
  z-index: 15;
}

.stickyBarMenuShow {
  transform: translateY(0);
}

.stickyBarBagButton.inactive {
  display: none;
}

.stickyBarMenu .btn {
  height: 48px !important;
}

.stickyBarBagButtonWishlist {
  position: absolute;
  line-height: 1;
}

.stickyAddToWishlist.active {
  transform: translateX(0);
}

.stickyAddToWishlist {
  transform: translateX(100%);
  transition: transform 0.2s cubic-bezier(0.9, 1.9, 0.1, 0.5);
}

.stickyViewToWishlist.active {
  opacity: 1;
}

.stickyViewToWishlist {
  opacity: 0.5;
  transition: opacity 0.25s ease-out;
}

.viewBtnContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 3.4;
}

.buttonActive {
  background-color: $button-secondary-hover-bg-mobile !important;
  border-color: $button-secondary-hover-border-mobile !important;
  color: $button-secondary-hover-color-mobile !important;
}

@media (max-width: $mobile-max-width) {
  #gnatta-chat-container {
    bottom: 6rem !important;
  }

  button#gwc-chatAvailable,
  button#gwc-chatBusy,
  button#gwc-chatOffline {
    bottom: 6rem !important;
  }

  .exclusiveOfferWrap {
    bottom: 48px !important;
  }
}

.product-detail__attribute__display-name-fitVariants {
  color: $black;
}

.product-detail__attribute__fitVariants-selected-length {
  color: $black;
  text-transform: capitalize;
  font-weight: 700;
}

.fit-variation {

  .fit-style {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    text-transform: uppercase;
    border: 1px solid #dee2e6;

    a {
      display: block;
      width: 100%;
      height: 100%;
      text-align: center;
      line-height: 2.8125rem;
    }

    span {
      font-weight: 600;
      padding: 5px 16px;
    }

    &:first-child {
      margin-left: 0;
    }
  }

  .selectedFitVariant {
    border: 1px solid black;
  }

  .outOfStock {
    position: relative;
    background-image: url('../../../../static/default/images/size-unavailable.svg');
    background-size: 100% 100%;
    color: $border-color;

    span {
      background-color: white;
      color: #dee2e6;
      line-height: 2.8125rem;
    }

    &:focus {
      outline: 0;
    }
  }
}

.bestprice {
  table {
    width: 15rem;
  }
  span {
    font-size: 14px;
    .value {
      color: #cc3530;
      text-align: center;
    }
    .date {
      color: #4f4f4f;
    }
  }
  .price-label {
    cursor: pointer;
    color: $neutral-grey;
    text-decoration: underline;
    font-size: 15px;
  }
  .bestprice_close-icon {
    font-size: 15px;
    vertical-align: middle;
  }
}

.recommendation-carousel-pdp {
  .product {
    &-tile {
      &__image {
        img {
          display: inline-block;
        }
      }
    }
  }
  .product-tile__body {
    .product-tile__name a,
    .product-tile__sticker a {
      white-space: initial;
    }
  }
}

.klarna-pdp-osm {
  min-height: 8.125rem;

  /* need custom media query as klarna placeholder size changes at this width */
  @media (min-width: $mobile-min-width) {
    min-height: 5.625rem;
  }
}

.paypal-pdp-osm {
  min-height: 2.5rem;
}
