.max-w-850 {
  max-width: 850px !important;
}

.max-w-890 {
  max-width: 890px !important;
}

.min-h-200 {
  min-height: 200px !important;
}

.min-w-200 {
  min-width: 200px !important;
}

.min-h-320 {
  min-height: 320px !important;
}

.min-w-320 {
  min-width: 320px !important;
}

.max-h-200 {
  max-height: 200px !important;
}

.max-w-200 {
  max-width: 200px !important;
}

.max-h-320 {
  max-height: 320px !important;
}

.max-w-320 {
  max-width: 320px !important;
}

.category-carousel-container {

  .category-carousel {
    padding: 16px 0;
    background: rgba(255, 255, 255, 0.4);

    .category-title {
      margin: 0;
      padding-top: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $accent3;
    }

    .glide__slide {
      max-width: 320px;
      min-width: 320px;
    }

    .pad15 {
      text-align: center;
      background: $accent2;
      color: $black;
    }

    .glide__arrows {
      .glide__arrow {
        color: $accent3;
        padding: 20px;
        background: $white;
        border: 1px solid $white;
        border-radius: 0;
        background-repeat: no-repeat;
        background-position: center;

        @include media-breakpoint-down(xs) {
          display: none;
        }

        &:hover {
          color: $accent3;
          background-color: $grey1;
          border: 1px solid $grey2;
          opacity: 1;
        }

        &:active {
          background-color: $grey3;
          border: 1px solid $black;
          color: $white;
        }
      }

      .glide__arrow--left {
        left: 0;
        background-image: url('../../../../../static/default/images/icons/prev.svg');
      }

      .glide__arrow--right {
        right: 0;
        background-image: url('../../../../../static/default/images/icons/next.svg');
      }

      .glide__arrow--disabled {
        display: none;
      }
    }
  }
}
