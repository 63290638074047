#footer {
  padding-top: 2rem;
  @include media-breakpoint-up(sm) {
    padding-top: 5rem;
  }
  .top-bottom-border {
    @include media-breakpoint-up(sm) {
      border-top: rem-calc(1) solid $grey4;
      border-bottom: none;
    }

    @include media-breakpoint-down(xs) {
      border-bottom: none;
    }
  }
  .contacts {
    @include font-size-letter-spacing(rem-calc(16), rem-calc(0), rem-calc(16), rem-calc(0));
    .footer-contacts-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .footer-contacts-item {
      text-align: left;
      padding: 8px 0 8px 8px;
      margin: 8px 0;
      background: $grey2;
      width: 100%;

      a {
        color: $grey3;
      }
    }

    .footer-contacts-link {
      @include media-breakpoint-up(sm) {
        width: auto;
        background-color: transparent;
        border: none;
        padding-top: 0;
        padding-bottom: 0;
        margin-right: 0;

        a {
          border: none;
          padding-top: 0;
          padding-bottom: 0;
          margin-right: 0;
          color: $grey3;
        }

        p {
          text-transform: uppercase;
        }

        .font-icon {
          display: inline-block;
        }
      }
      h5 {
        color: $accent3;
        margin-top: 1rem;
      }
    }
    .contacts-asset-left {
      @include media-breakpoint-up(sm) {
        display: block;
      }
    }
    .email-newsletter {
      max-width: 25rem;
      text-align: left;
      margin: 0 auto;
      padding: 0 1rem;

      @include media-breakpoint-down(xs) {
        max-width: unset;
      }

      &__form {
        width: auto;
        max-width: none;
      }

      &__email-input {
        font-size: 1rem;
        border-bottom: solid 2px $black;
        padding-left: 0.75rem;
        color: $accent3;
        @include font-size-letter-spacing(1rem, 0, 1rem, 0);

        &::placeholder {
          color: $accent3;
        }
      }

      &__title {
        color: $black;
        @include font-size-letter-spacing(rem-calc(16), rem-calc(-0.8), rem-calc(16), rem-calc(-0.8));
      }

      &__submit {
        right: 0.75rem;

        .font-icon::before {
          color: $black;
          border: 1px solid $grey2;
          padding: 5px;
          font-size: 0.7rem;
          position: relative;
        }
      }

      .subscribe_note {
        color: $accent3;
      }
    }
  }

  .fullwidth-color {
    @include media-breakpoint-up(sm) {
      background-color: $grey1;
    }

    .footer-columns {
      @include media-breakpoint-down(md) {
        max-width: 100%;
      }

      h6 {
        letter-spacing: normal;
        margin-left: 1.5rem;
        margin-right: 2rem;
        @include media-breakpoint-up(sm) {
          margin-left: 0;
          margin-right: 0;
          font-weight: bold;
        }

        .icon {
          font-weight: normal;
        }
      }

      .collapse-trigger {
        @include media-breakpoint-up(sm) {
          pointer-events: none;
        }
      }

      .accordion {
        &-element {
          @include media-breakpoint-up(sm) {
            display: flex;
            justify-content: center;
          }
        }
        .footer-column {
          h6,
          a {
            @include media-breakpoint-up(sm) {
              color: $black;
            }
          }
          @include media-breakpoint-down(xs) {
            border-bottom: none;
            border-top: 1px solid $black;
            @include margin-horizontal(unset, unset);
          }
          @include media-breakpoint-between(sm, md) {
            @include margin-horizontal(unset, unset);
            border-bottom: unset;

            ul {
              padding-inline-start: unset;
            }
          }

          &.last-column {
            @include media-breakpoint-between(sm, md) {
              border-bottom: none;
            }
          }
        }

        ul {
          letter-spacing: 0;
          line-height: 110%;
          li {
            @include media-breakpoint-up(sm) {
              font-weight: normal;
              padding-top: map-get($spacers, 1);
              padding-bottom: map-get($spacers, 1);
            }
          }
        }
      }
    }
  }

  .fullwidth {
    @include media-breakpoint-down(md) {
      max-width: 100%;
    }

    .bottom-footer-icons {
      .social {
        @include media-breakpoint-between(sm, md) {
          background-color: unset;
          @include padding-vertical(unset, unset);
        }

        @include media-breakpoint-down(xs) {
          @include padding-vertical(1rem, 1rem);

          img {
            height: 3.5rem;
            width: 3.5rem;
          }
        }

        a {
          [class*="font-icon icon-"] {
            @include media-breakpoint-up(sm) {
              font-size: rem-calc(30);
            }
          }
        }
      }

      .payment-methods {
        @include media-breakpoint-up(sm) {
          padding-top: 0;
          padding-bottom: 0;
        }

        @include media-breakpoint-down(xs) {
          padding-top: 2rem;
          padding-bottom: 1rem;
        }

        img {
          @include media-breakpoint-only(sm) {
            max-width: 100%;
          }
        }
      }

      .site-reviews {
        @include media-breakpoint-between(sm, md) {
          padding-top: 1rem;
        }
      }
    }

    .footer-copyright {
      font-size: 13px;
      letter-spacing: 0;
      line-height: 110%;
      @include media-breakpoint-up(sm) {
        padding-top: 0;
        padding-bottom: rem-calc(32);
        color: $black;
        background-color: $white;
      }

      @include media-breakpoint-down(xs) {
        padding-top: 0;
        padding-bottom: rem-calc(32);
        color: $black;
        background-color: $white;
      }
    }
  }
}
