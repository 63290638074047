.account-dashboard {
  .account-nav {
    &__item {
      &-icon {
        @include make-col-ready();
        @include make-col(2);

        @include media-breakpoint-up(lg) {
          max-width: 25%;
        }

        img {
          height: 42px;
          width: 42px;
        }
      }
      &-text {
        @include make-col-ready();
        @include make-col(8);
        font-weight: 700;
        color: $grey3;
      }

      &--logout {
        @include btn-secondary-is();
        display: flex !important;
        justify-content: center;
        align-items: center;

        .account-nav__item-icon {
          display: none !important;
        }
        .account-nav__item-text {
          text-decoration: none;
          text-align: center;
          padding: 0;
          color: unset;
          font-weight: 500;
          &::first-letter {
            text-transform: uppercase;
          }
        }
      }
    }
  }
}
