@font-face {
  font-family: 'Funkis';
  src:
    url('../../../static/fonts/FunkisA.2.3-Regular.woff2') format('woff2'),
    url('../../../static/fonts/FunkisA.2.3-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Funkis';
  src:
    url('../../../static/fonts/FunkisA.2.3-Medium.woff2') format('woff2'),
    url('../../../static/fonts/FunkisA.2.3-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Funkis';
  src:
    url('../../../static/fonts/FunkisA.2.3-SemiBold.woff2') format('woff2'),
    url('../../../static/fonts/FunkisA.2.3-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Funkis';
  src:
    url('../../../static/fonts/FunkisA.2.3-Bold.woff2') format('woff2'),
    url('../../../static/fonts/FunkisA.2.3-Bold.woff') format('woff');
  font-weight: 700;
  font-weight: 800;
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'InsideStoryIconFont';
  font-style: normal;
  font-weight: normal;
  src:
    url('../../../static/fonts/InsideStoryIconFont.eot?dkp33s#iefix') format('embedded-opentype'),
    url('../../../static/fonts/InsideStoryIconFont.ttf?dkp33s') format('truetype'),
    url('../../../static/fonts/InsideStoryIconFont.woff?dkp33s') format('woff'),
    url('../../../static/fonts/InsideStoryIconFont.svg?dkp33s#InsideStoryIconFont') format('svg');
}

@font-face {
  font-family: 'Petrona';
  src: url('../../../static/fonts/Petrona-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Petrona';
  src: url('../../../static/fonts/Petrona-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Petrona';
  src: url('../../../static/fonts/Petrona-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Petrona';
  src: url('../../../static/fonts/Petrona-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Petrona';
  src: url('../../../static/fonts/Petrona-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
