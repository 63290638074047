.product-detail {
  background-color: $cultured;
  &__brand-name {
    color: $accent3;
    font-size: 1rem;
  }

  &__product-name {
    margin-bottom: 0.75rem;
    @media (min-width: $bp-large) {
      margin-bottom: 1rem;
    }

    &--text {
      color: $accent3;
      @include font-pdp-h1();
      width: calc(100% - 40px);
    }
  }

  .product-breadcrumb {
    padding-left: 0;
    .breadcrumb {
      padding: 10px 0 10px 6px;
      .breadcrumb-item a {
        color: $grey3;
        font-weight: 300;
        font-size: 14px;

        &:hover {
          color: $secondary;
        }
      }
    }
  }

  .usp {
    &-text {
      padding: 0 0 0 8px;
      @include text-link();
      @media (min-width: $bp-large) {
        font-size: 14px;
      }
      &::after {
        margin-top: 0;
      }
    }
    &_container {
      padding: 28px 0;

      @include media-breakpoint-down(lg) {
        padding: 32px 0;
      }

      .delivery-link {
        padding-bottom: 20px;
        @include media-breakpoint-up(lg) {
          padding-bottom: 1rem;
        }
      }
    }
  }

  &__product-images {
    .primary-images {
      @include media-breakpoint-up(lg) {
        &__wrapper {
          padding-left: 0;
        }
        &__image-wrapper--padded {
          padding: 0 0.625rem 0.625rem 0;
        }
      }

      &__image-wrapper {
        &__inner {
          position: relative;
          overflow: hidden;
          aspect-ratio: 1 / 1; // stylelint-disable-line
          padding-bottom: 0;
          background-color: $white;

          .pinch-zoom-container {
            position: relative;
            overflow: hidden;
            padding-bottom: 100%;

            .primary-images__image {
              position: absolute !important;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
  }

  &__prices {
    display: flex;
    align-items: center;
    span {
      font-size: 1rem;
    }

    .from-label {
      color: $accent3;
      font-size: rem-calc(16);
      font-weight: 600;
    }

    .has-strike-through {
      @include font-b6();
    }

    .strike-through {
      margin-right: 0.5rem;
      @include font-pdp-price-strike-through;

      span {
        font-size: 1rem;
      }
    }

    margin-bottom: 20px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 24px;
    }
  }

  &__promotions {
    @include font-pdp-promotion-label;
    max-width: fit-content;
    .callout {
      margin: 0 !important;
    }
  }

  &__ratings .rating-star {
    color: $secondary;
  }

  &__attribute {
    $attrRoot: &;

    &__display-name,
    &__display-name-fitVariants {
      color: $accent3;
      @include font-pdp-h3();
      text-transform: capitalize;
      font-weight: 600;
    }

    &__display-value {
      @include font-pdp-h3();
      font-weight: 300;
    }

    &--color,
    &--size {
      margin: 0 !important;
    }

    &--color & {
      padding: 0;
      &__label {
        margin-bottom: 0 !important;
        padding: 12px 0 !important;
        border-top: 1px solid $border-grey;
        @include media-breakpoint-up(lg) {
          padding: 20px 0 !important;
        }
        &--inner {
          max-width: 100%;
          flex: auto;
        }
      }

      @include media-breakpoint-up(lg) {
        &__value-wrapper {
          margin-bottom: 4px;
        }
      }

      &__value {
        @media only screen and (min-width: $bp-large) {
          width: 26px;
          height: 26px;
        }

        &__icon {
          @media only screen and (min-width: $bp-large) {
            height: 1.25rem;
            width: 1.25rem;
          }
        }
      }
    }

    &--size & {
      margin: 0 !important;
      &__label {
        margin-bottom: 0 !important;
        padding: 0.75rem 0 0.75rem 0;
        border-top: 1px solid $border-grey;
        @include media-breakpoint-up(lg) {
          padding: 1.25rem 0 1.25rem 0;
        }
      }
      &__values-row {
        align-items: center;
        gap: 8px;
        &:has(div) {
          padding-bottom: 1.25rem;
          @include media-breakpoint-up(lg) {
            padding-bottom: 1.5rem;
          }
        }
      }
      &__value-wrapper {
        height: 100%;
        line-height: 100%;
        padding-left: 0;
        padding-right: 0;
        max-width: fit-content;
        text-transform: capitalize;
        flex: unset;
        margin-bottom: 0;
      }

      &__value {
        @include btn-pill();

        &--low-stock {
          border: 1px dashed $grey3;
          background: unset;
        }

        &--unselectable {
          @include btn-disabled;
          text-decoration-line: line-through;

          &:hover {
            @include btn-disabled;
            text-decoration-line: line-through;
          }

          &:not(:disabled):not(.disabled):active {
            text-decoration-line: none;
            background-color: $disabled-active;
            color: $accent1;
            border-color: $disabled-active;
          }
        }

        &--unselectable #{$attrRoot}__value__text {
          background-color: unset;
        }

        &--current {
          background-color: $disabled;
          border: 1px solid $grey3;
        }

        &__text {
          padding: 0 !important;
        }
      }
    }

    &__error {
      color: $accent1;
      @include font-pdp-text-danger();
    }

    &__fitVariants {
      padding: 12px 0;
      text-transform: capitalize;
      border-top: 1px solid $border-grey;

      &__container {
        margin-bottom: 12px;
        @include media-breakpoint-up(lg) {
          margin-bottom: 20px;
        }
      }

      &-selected-length {
        font-weight: 300;
        color: $grey3;
      }

      @include media-breakpoint-up(lg) {
        padding: 20px 0;
      }
      .fit-variation {
        flex-wrap: wrap;
        gap: 8px;
        .fit-style {
          margin: 0;
        }
      }
    }
  }

  &__oos-message,
  .low_stock_message {
    border-top: 1px solid $border-grey;
    @include font-pdp-text-danger();
    padding: 1.25rem 0;
    margin-bottom: 0;
    @include media-breakpoint-down(lg) {
      padding: 1.5rem 0;
      margin-bottom: 0;
    }
  }

  &__add-to-cart {
    .add-to-cart {
      @include btn-primary-is();

      letter-spacing: -0.3px;
      @include media-breakpoint-up(lg) {
        letter-spacing: -0.32px;
      }
      width: 100%;
    }
    .add-to-cart.btn-secondary:disabled {
      @include btn-disabled();
      background-color: $disabled;
      color: $grey3;
      border-color: $disabled;

      &:active {
        background-color: $disabled-active;
        color: $accent1;
        border-color: $disabled-active;
      }
    }
    &__notification {
      background-color: transparent;
    }
  }

  &__accordion-container {
    margin-bottom: 2.5rem;
    @media screen and (min-width: $bp-medium) {
      margin-bottom: 4.5rem;
    }
  }

  &__accordion-heading {
    .btn {
      padding: 0;
      @include font-pdp-h3();
      font-weight: 600;
    }
  }

  &__accordion {
    .icon-chevron {
      &-up,
      &-down {
        &::before {
          font-size: 14px;
        }
      }
    }
    &__container {
      div {
        background-color: $cultured;
      }
      .card {
        &-header {
          padding: 12px 0;
          @include media-breakpoint-up(lg) {
            padding: 20px 0;
          }
        }
        &.border-top {
          border-top: 1px solid $border-grey !important;
        }
      }
      &.border-bottom {
        border-bottom: 1px solid $border-grey !important;
      }
    }
    .card-body {
      color: $grey3;
      p,
      li {
        @include font-pdp-paragraph();
      }
      ul {
        padding: 0 0 20px 16px;
      }
    }

    #collapseOne {
      .card-body {
        padding: 0;
        position: relative;

        div {
          margin-top: 0 !important;
        }

        p {
          margin-bottom: 20px;
        }

        p:first-child {
          @include font-pdp-h3;
          margin-bottom: 4px;
        }
      }
      .card-body > div > p:last-of-type {
        margin-bottom: 1.5rem;
        @include media-breakpoint-up(lg) {
          margin-bottom: 2rem;
        }
      }
    }
  }

  &__social-icons {
    i {
      color: #353535;
    }
    .icon-fa-twitter::before {
      content: url('../../../../static/default/images/x-logo.svg');
    }
  }

  &:not(.product-detail--quickview) {
    .primary-images {
      .carousel-indicators {
        margin-left: 10%;
        margin-right: 10%;
        li {
          width: $carousel-indicator-width;
          height: 4px;
          border-radius: 0;
          @media only screen and (max-width: $bp-small-max) {
            width: 2rem;
            margin: 0 4px;
          }
        }
        .active {
          background: rgba(0, 0, 0, 0.5);
        }
      }

      .carousel-controls {
        .image-nav-arrow {
          display: none;
        }
        .carousel-control-prev {
          background-image: url('../../../../static/default/images/icons/dPrev.svg');
          background-position: center;
          background-repeat: no-repeat;
          margin-left: 0.5rem;
        }
        .carousel-control-next {
          background-image: url('../../../../static/default/images/icons/dNext.svg');
          background-position: center;
          background-repeat: no-repeat;
          margin-right: 0.5rem;
        }
      }
    }
  }

  &__nav-row {
    &__previous {
      border-right: unset;
    }
    &__previous,
    &__next {
      a {
        color: $accent3;
        font-weight: 700;
      }
    }
  }

  .size-guide-link {
    max-width: fit-content;
    padding: 0 !important;
  }

  .size-chart {
    letter-spacing: -0.3px;
    text-decoration: none;
    @include text-link();

    @media (min-width: $bp-large) {
      font-size: 14px;
    }

    &::after {
      margin-top: 0;
    }

    @include media-breakpoint-up(lg) {
      letter-spacing: -0.28px;
    }
  }

  &__size-chart {
    display: flex;
    justify-content: end;
    &__content {
      .size-chart__measure-unit-switch {
        .radio-link {
          @include checkout-option;
          width: rem-calc(95);
          height: rem-calc(40);
          border: rem-calc(1) solid $border-color;

          &.active {
            @include checkout-selected-option;

            border: rem-calc(1) solid $secondary;
          }

          .radio-label {
            margin-left: map-get($spacers, 4);
          }
        }
      }
    }
    &__container {
      a.btn {
        @include btn-primary-small();
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  button.add-to-wish-list {
    float: right;
    height: auto;
    line-height: 1;
    border: 0;
    padding: 0;
    background-color: transparent !important;

    .font-icon {
      font-size: rem-calc($fontsize-xmedium);
    }

    @include media-breakpoint-up(lg) {
      &:hover,
      &:active {
        background: transparent;

        &:not(.disabled):not(:disabled) {
          background: transparent;
        }

        .font-icon {
          color: $accent3;
        }

        .wishlist-icon svg path {
          fill: $secondary;
          stroke: $secondary;
        }
      }
    }

    .wishlist-icon {
      display: block;
      width: rem-calc(20);
      height: rem-calc(20);

      &--active {
        display: none;
      }

      svg {
        width: rem-calc(20);
        height: rem-calc(20);
      }
    }

    &[disabled] {
      background-color: transparent;
      opacity: 1;

      .wishlist-icon {
        svg {
          path {
            fill: $accent3;
          }
        }

        &--inactive {
          display: none;
        }
        &--active {
          display: inline;
        }
      }
    }
    &.active {
      background-color: transparent;
      opacity: 1;

      .wishlist-icon {
        svg {
          path {
            fill: $accent3;
          }
        }

        &--inactive {
          display: none;
        }
        &--active {
          display: inline;
        }
      }

      &:focus {
        box-shadow: none !important;
      }

      @include media-breakpoint-up(lg) {
        &:hover {
          .wishlist-icon svg path {
            fill: $secondary;
            stroke: $secondary;
          }
        }
      }
    }
  }

  .product-detail__bottom-recommendations {

    .product-tile__body {
      padding-top: 16px;

      .rating-star-empty {
        color: $secondary;
      }
    }

    .product-tile__action {
      display: none;
    }

    .product-tile__name {
      .link {
        font-weight: 700;
      }
    }
  }
  &__set-container {
    .product-detail__product-images {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    @include media-breakpoint-down(md) {
      .product-detail__set-heading,
      .product-detail__prices__set,
      .product-details__accordion-container,
      .product-detail__details-section--set {
        padding-left: 24px;
        padding-right: 24px;
      }
    }
    @include media-breakpoint-down(sm) {
      .product-detail__set-heading,
      .product-detail__prices__set,
      .product-details__accordion-container,
      .product-detail__details-section--set {
        padding-left: 16px;
        padding-right: 16px;
      }
    }
    .set-items {
      .product-detail__details-section {
        .product-detail__brand-name {
          margin-top: 0.5rem;
        }
        .product-detail__delivery-return-links {
          display: none;
        }
        @include media-breakpoint-up(lg) {
          &-sticky-container {
            max-width: 100%;
          }
          &-sticky {
            max-width: 100%;
          }
        }
      }
      .set-item {
        margin-top: 38px;
        .product-detail__item-link {
          display: none;
        }
        .product-detail__ratings {
          line-height: 110%;
        }
      }
    }
  }

  .product-detail__fit-rating {
    display: block !important;
  }
}

.stickyBarBagButton.stickyAddToBag {
  @include btn-primary-is();
}

.stickyAddToWishlist {
  color: #fff !important;
  background: $secondary1 !important;
  border: none !important;
  border-left: 1px solid #fff !important;
}

.stickyViewToWishlist {
  color: $secondary !important;
  background: #fff !important;
  border: none !important;
  border-top: 1px solid $secondary !important;
  border-left: 1px solid $secondary !important;
  svg {
    path {
      fill: $secondary;
    }
  }
}

.stickyBarMenu {
  .sticky-atb-container {
    padding: 0;
    margin: 0;
  }

  .sticky-atb__product-name--text {
    display: none !important;
  }

  .rd-sticky-atb-wishlist-atom {
    display: none;
  }

  .rd-sticky-atb-cart-atom {
    width: 100%;
  }

  .stickyViewToBag,
  .stickyAddToBag,
  .stickyViewToWishlist,
  .stickyAddToWishlist {
    width: 100%;
  }

  &:first-child {
    @media screen and (max-width: $bp-small-max) {
      padding: 8px !important;
    }
    @media screen and (max-width: $bp-medium-max) {
      padding: 16px !important;
    }
    background-color: #faf8f5;
  }

  .col-9 {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
  }

  .col-3 {
    display: none;
  }
}
.stickyBarBagButton.inactive {
  display: none;
}

.stickyBarBagButtonWishlist svg {
  width: 1.5rem;
  height: 1.5rem;
}

.stickyBarBagButtonWishlist .addBtn {
  color: #fff;
}

.viewBtnText {
  font-size: 10px;
  margin-right: 4px;
}

.stickyViewToBag {
  background: #fff !important;
}

.product-detail__bottom-recommendations__tab-content,
.product-detail__bottom-recommendations,
.product-detail__product-carousel {
  .container-new {
    max-width: 100%;
  }
}

.product-detail__product-carousel {
  h5 {
    @include font-pdp-h2();
    margin-bottom: 1.25rem;
    @media screen and (min-width: $bp-medium) and (max-width: $md-width) {
      margin-bottom: 1.5rem;
    }
    @media screen and (min-width: $md-width) {
      margin-bottom: 2rem;
    }
  }
  margin-bottom: 4.5rem;
  @media screen and (min-width: $bp-medium) {
    margin-bottom: 2.375rem;
  }
  @media screen and (min-width: $md-width) {
    margin-bottom: 4.5rem;
  }
}

.product-detail__details-row .glide__arrow--left.glide__arrow--disabled {
  display: none;
}

.product-detail__details-row .glide__arrow--right.glide__arrow--disabled {
  display: none;
}

.product-detail__product-bottom .product-recommendations__heading {
  margin-top: 0;
  text-align: left;
}

.space-bottom {
  margin-bottom: rem-calc(40);
  @include media-breakpoint-up(lg) {
    margin-bottom: rem-calc(88);
  }
  padding-left: 16px;
}

.product-detail:not(.product-detail--set):not(.set-item) {
  .product-detail__details-section {
    .product-detail-sticky-container {
      @media only screen and (min-width: $md-width) {
        max-width: 100%;
      }
      @media only screen and (max-width: $md-width) {
        max-width: 100%;
      }
    }

    @media only screen and (min-width: $md-width) {
      padding-right: 4.5rem;
      padding-left: 0;
      max-width: 25%;
    }

    @media only screen and (max-width: $md-width) {
      padding-right: 3.5rem;
      padding-left: 0;
      max-width: 33%;
    }

    @media only screen and (max-width: $bp-medium-max) {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
      max-width: 100%;
    }

    @media only screen and (max-width: $bp-small-max) {
      padding-left: 1rem;
      padding-right: 1rem;
      max-width: 100%;
    }
  }

  .product-detail__product-images {
    padding-left: 0;
    padding-right: 0;
    @media only screen and (min-width: $md-width) {
      padding-left: 4.5rem;
      padding-right: 28px;
      max-width: 75%;
      flex: auto;
    }

    @media only screen and (max-width: $md-width) {
      padding-left: 3.5rem;
      padding-right: 19px;
      max-width: 67%;
      flex: auto;
    }

    @media only screen and (max-width: $bp-medium-max) {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
      max-width: 100%;
    }

    @media only screen and (max-width: $bp-small-max) {
      padding-left: 0;
      padding-right: 0;
      max-width: 100%;
    }

    .primary-images {
      &__wrapper {
        padding-left: 0;
      }
      &__image-wrapper--padded {
        @media only screen and (min-width: $md-width), screen and (max-width: $md-width) {
          padding: 0 1.25rem 1.25rem 0;
        }
        @media only screen and (max-width: $bp-medium-max) {
          padding: 0;
        }
      }
      .carousel-control-prev,
      .carousel-control-next {
        height: 2.5rem;
        width: 2.5rem;
        top: calc(50% - 1rem);
      }
    }
  }
  .product-detail__product-bottom {
    @media only screen and (min-width: $md-width) {
      padding-left: 4.5rem;
      padding-right: 4.5rem;
    }

    @media only screen and (max-width: $md-width) {
      padding-left: 3.5rem;
      padding-right: 3.5rem;
    }

    @media only screen and (max-width: $bp-medium-max) {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }

    @media only screen and (max-width: $bp-small-max) {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    #looksGreatWith {
      padding-top: 2rem;
      background-image: url('../../../../static/default/images/border.png');
      background-repeat: repeat-x;
      background-position: top left;

      @media only screen and (min-width: $bp-large) {
        padding-top: 3.5rem;
      }
      div {
        padding: 0;
      }
    }
  }

  .product-detail__tile-row {
    .product-detail__tile-row__item {
      padding: 0 8px;
    }
  }
}
