.newsletter {
  &__heading {
    color: $grey3;
  }

  .newsletterForm {
    label {
      color: $grey3;
    }
  }
}

.preferred-store {
  &__heading {
    color: $grey3;
  }

  .address-form-container {
    select,
    label {
      color: $grey3;
    }

    select::placeholder {
      color: $accent3;
    }

    input {
      color: $accent3;

      ::placeholder {
        color: $accent3;
      }
    }
  }
}

.preference-centre {
  .newsletter {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  form {
    input,
    select {
      color: $accent3;
      border-color: $accent3;
      &::placeholder {
        color: $accent3;
      }
    }
    button,
    .btn {
      &.btn-secondary {
        @include btn-primary-is();
      }
    }
  }
}
