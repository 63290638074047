.grey-background {
  padding: 24px 2px 48px 32px;
  background-color: $grey1;

  @include media-breakpoint-down(sm) {
    padding: 24px 0 24px 0;
  }
}

.absolute-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.mission-component-figure {
  background-color: $grey1;
  .mission-heading {
    color: $accent3;
    text-align: center;
    max-width: 90%;
    @include media-breakpoint-up(sm) {
      text-align: left;
      max-width: 100%;
    }
  }

  .mission-copy-title {
    text-align: left;
  }

  .mission-image {
    span {
      font-size: 14px;
    }
    picture {
      width: 100%;
      height: 100%;
    }

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      @include media-breakpoint-up(sm) {
        width: auto;
        max-width: 100%;
      }
    }
  }

  .mission-copy-sign {
    border: 4px solid $accent3;
    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-left: 0;
    border-left: 0;
    margin: 0 10px;
    position: relative;

    &.limit-height {
      max-height: 450px;
      overflow: hidden;
      @include media-breakpoint-up(sm) {
        max-height: unset;
      }
    }

    p {
      color: $accent3;
      font-size: 16px;
    }

    &::before {
      content: ' ';
      height: 2rem;
      position: absolute;
      top: 0;
      left: 0;
      width: 4px;
      background-color: $accent3;
    }

    &::after {
      content: ' ';
      height: 2rem;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 4px;
      background-color: $accent3;
    }
  }

  .readmore-wrapper {
    position: absolute;
    width: calc(100% - 20px);
    bottom: 0;
    height: 200px;
    display: flex;
    align-items: flex-end;
    background-image: linear-gradient(to bottom, rgba(255, 0, 0, 0), rgba(209, 209, 209, 1), rgba(209, 209, 209, 1));

    button {
      max-height: 44px;
      margin: 20px auto;
      border-radius: 5px;
      max-width: 65%;
      font-weight: 700;
      color: $secondary;
    }
  }
}
.cat-selection-button {
  @include btn-primary-small();
  a {
    color: #fff;
  }
}
.carousel-bg {
  display: flex;
  padding: 16px;
  position: relative;
  background: rgba(255, 255, 255, 0.4);
  @include media-breakpoint-down(lg) {
    padding-right: 0;
  }

  .product-item {
    background-color: white;
    max-width: 188px;

    .p-details {
      font-weight: 400;
      font-size: 16px;
      text-align: left;
      color: $accent3;
      padding: 10px;

      p {
        text-transform: uppercase;
        font-weight: 700;
        margin-bottom: 0;
      }
    }
  }
}
.page-designer-reference {
  overflow-x: hidden;
}

.cat-search-selection {
  .glide__arrows {
    .glide__arrow {
      &:hover {
        color: $accent3;
        background-color: $grey1;
        border: 1px solid $grey2;
        opacity: 1;
      }

      &:active {
        background-color: $grey3;
        border: 1px solid $black;
        color: $white;
      }
    }
  }

  &::before {
    background-color: rgba(0, 0, 0, 0.1);
    content: '';
    inset: 0;
    position: absolute;
  }

  h2 {
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
  }
}
