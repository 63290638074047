.checkout-billing {
  &__payment-input:not(:checked) ~ &__payment-selector--SAVED_CREDIT_CARD::after {
    background-color: $white;
  }

  &__submit-btn-wrapper {
    margin-left: 0;
  }

  &__payment-form {
    &.checkout-billing__payment-form--AdyenComponent {
      .paymentMethod-radio {
        margin-bottom: 1rem;

        label {
          font-weight: 700;
          font-size: 20px;
          line-height: 110%;
        }
      }

      .adyen-checkout {
        &__input {
          border-radius: 0;
          border: 1px solid #dee2e6;

          &--focus,
          &:active,
          &:focus,
          &:hover {
            border: 1px solid #dee2e6;
            box-shadow: unset;
          }
        }

        &__label {
          &--focused {
            .adyen-checkout__label__text {
              color: $grey5;
            }
          }

          &__text {
            color: $grey5;
            font-size: 16px;
            line-height: 110%;
          }
        }

        &__checkbox__input {
          + .adyen-checkout__checkbox__label::before {
            border-width: 2px 4px 4px 1px;
            height: 13px;
            width: 7px;
            left: 0;
            top: 1px;
            border-color: transparent $accent3 $accent3 transparent;
          }
        }

        &__checkbox__input:hover,
        &__checkbox__input:focus,
        &__checkbox__input:checked:hover {
          + .adyen-checkout__checkbox__label::after {
            border-color: $accent3;
            box-shadow: unset;
          }
        }

        &__checkbox__input:checked {
          + .adyen-checkout__checkbox__label::after {
            background-color: transparent;
            border: 1px solid 1px $accent3;
          }
        }

        &__checkbox__label {
          font-weight: 700;
          font-size: 16px;
          text-transform: capitalize;
          color: $accent3;
        }
      }
    }

    &.checkout-billing__payment-form--GIFT_CARD {
      .gc-content__description {
        color: $accent3 !important;
        padding-top: 1rem;
      }

      .btn-secondary {
        @include btn-secondary-medium();
        width: 100%;
      }

      .cancel-gc-btn {
        font-weight: 700;
      }
    }
  }

  &__address {
    .checkout-billing__address-card-title {
      font-weight: 700;
      font-size: 20px;
      line-height: 110%;
      text-transform: capitalize;
      padding-bottom: 1rem;
    }
  }

  &__method-name {
    font-weight: 700;
    font-size: 20px;
    line-height: 110%;
    letter-spacing: -0.05em;
  }

  &__payment--PayPal {
    .paypal-content__description {
      color: $accent3;
      padding-top: 1rem;
      font-weight: 700;
      line-height: 110%;
    }
  }

  &__submit-terms__wrapper {
    line-height: 110%;
    color: $accent3;
  }
}

#paymentMethodsList {
  padding-left: 0;

  .paymentMethod {
    .paymentMethod-radio {

      input[type="radio"] {
        width: auto;
        height: auto;
      }

      label {
        display: flex;
        align-items: center;
      }
    }
  }
}

.checkout-payment {
  margin-top: 1.5rem;
}
