$active-btn-selector: ":not(:disabled):not(.disabled):active";
$active-focus-btn-selector: ":not(:disabled):not(.disabled):active:focus";
@mixin btn-disabled() {
  box-shadow: none;
  background-color: $grey1-disabled;
  border-color: $grey1-disabled;
  color: $grey2;
  opacity: 1;
}

@mixin btn-primary-is() {
  @include btn-primary();
  border: none;
  padding: 12px 20px;
  display: inline-block;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background-color: $grey3;
  height: 39px;
  line-height: 1;
  font-weight: 500;
  outline: none;
  font-size: 15px;

  &::first-letter {
    text-transform: uppercase;
  }

  @include media-breakpoint-up(lg) {
    padding: 14px 32px;
    height: 44px;
    line-height: 1;
    font-size: 16px;
  }

  &#{$active-focus-btn-selector} {
    color: $white;
    background-color: $secondary;
    box-shadow: none;
    outline: none;
  }

  &:disabled {
    @include btn-disabled();
    background-color: $disabled;
    color: $grey3;
  }

  &:disabled:hover {
    background-color: $disabled;
    color: $grey3;
  }

  &:disabled:active {
    background-color: $disabled-active;
    color: $accent1;
  }

  &:hover,
  &:focus {
    color: $white;
    background-color: $secondary;
    box-shadow: none;
    outline: none;
  }

  &#{$active-btn-selector} {
    background-color: $secondary-cta-active;
  }
}

@mixin btn-primary-small() {
  @include btn-primary-is();
  height: 39px;
  line-height: 1;
  padding: 12px 20px;
  font-size: 15px;
}

@mixin btn-primary-medium() {
  @include btn-primary-is();
  height: 44px;
}

@mixin btn-primary-large() {
  @include btn-primary-is();
  height: 48px;
}

@mixin btn-secondary-is() {
  @include btn-secondary-override();
}

@mixin btn-secondary-small() {
  @include btn-secondary-is();

  height: 40px;
}

@mixin btn-secondary-medium() {
  @include btn-secondary-is();

  height: 44px;
}

@mixin btn-secondary-large() {
  @include btn-secondary-is();

  height: 48px;
}

@mixin btn-tertiary() {
  height: 40px;
  border: 1px solid $secondary;
  border-radius: 4px;
  color: $secondary;
  font-weight: 700;
  line-height: 1;
  background-color: transparent;
  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }

  &:hover {
    border: 2px solid $secondary;
    color: $secondary;
    background-color: transparent;
  }

  &:active {
    background-color: $secondary !important;
    color: $white !important;
  }
}

@mixin btn-secondary-override() {
  background-color: transparent;
  border: 1px solid $grey3;
  border-radius: 0;
  color: $grey3;
  box-shadow: none;
  font-weight: 500;
  padding: 12px 20px;
  outline: none;
  font-size: 15px;
  text-transform: lowercase;
  line-height: 1;

  &::first-letter {
    text-transform: uppercase;
  }

  @include media-breakpoint-up(lg) {
    padding: 14px 32px;
    font-size: 16px;
  }

  &:hover {
    background-color: transparent;
    border-color: $secondary;
    color: $secondary;
  }

  &:focus {
    box-shadow: none;
    border-color: $secondary;
    color: $secondary;
  }

  &#{$active-btn-selector} {
    background-color: $secondary-light;
    border-color: $secondary;
    color: $secondary;
    box-shadow: none;

    &:focus {
      box-shadow: none;
      border-color: $secondary;
      color: $secondary;
    }
  }

  &:disabled {
    @include btn-disabled();
    background-color: transparent;
    border: 1px solid #9c9a96;
    color: #9c9a96;
  }

  &:disabled:active {
    color: $accent1;
    border: 1px solid $accent1;
    background-color: #faeae1;
  }
}

@mixin btn-pill() {
  @include btn-primary-is();
  border: 1px solid $disabled;
  background-color: $disabled;
  color: $grey3;
  border-radius: 100px;
  font-size: 14px;
  outline: none;
  box-shadow: none;
  padding: 12px 16px;

  @include media-breakpoint-up(lg) {
    font-size: 15px;
    padding: 14px 16px;
  }

  &:hover {
    background-color: $disabled;
    border-color: $secondary;
    color: $secondary;
  }

  &#{$active-btn-selector} {
    border-color: $secondary;
    color: $secondary;
    background-color: #b8e2e5;
    box-shadow: none;
    outline: none;
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }

  &:disabled {
    @include btn-disabled;
    text-decoration-line: line-through;

    &:active {
      text-decoration-line: none;
    }
  }
}

@mixin text-link() {
  font-weight: 500;
  color: $grey3;
  display: inline-flex;
  flex-direction: column;
  gap: 2px;
  font-size: 15px;

  @include media-breakpoint-up(lg) {
    font-size: 16px;
  }

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 1.5px;
    background-color: $grey3;
    margin-top: 0.1rem;
  }

  &:hover {
    color: $secondary;

    &::after {
      background-color: $secondary;
    }
  }
}

.btn-primary,
button.btn-primary,
.btn.btn-primary,
.btn-primary--light,
button.btn-primary--light,
.btn.btn-primary--light,
.btn-secondary,
button.btn-secondary,
.btn.btn-secondary,
.btn-secondary--subtle,
button.btn-secondary--subtle,
.btn.btn-secondary--subtle,
.btn-pill,
button.btn-pill,
.btn.btn-pill {
  text-transform: lowercase;
  display: inline-block;
  line-height: 1;

  &::first-letter {
    text-transform: uppercase;
  }

  .fa.fa-shopping-bag {
    display: none;
  }

  div {
    text-transform: lowercase;

    &::first-letter {
      text-transform: uppercase;
    }
  }
}

.btn-primary,
button.btn-primary,
.btn.btn-primary {
  @include btn-primary-is();
}

.btn-primary--light,
button.btn-primary--light,
.btn.btn-primary--light {
  @include btn-primary-is();
  background-color: $white;
  color: $grey3;
  border: none;

  &:hover {
    background-color: $secondary-light;
    color: $secondary;
  }

  &#{$active-btn-selector} {
    background-color: #b8e2e5;
    color: $secondary;
  }

  &:disabled {
    @include btn-disabled();
    background-color: $disabled;
    border: none;
    color: $grey3;

    &:active {
      background-color: $disabled-active;
      border: none;
      color: $accent1;
    }
  }
}

.text-link {
  @include text-link();
}

.text-link--light {
  @include text-link();
  color: $white;

  &::after {
    background-color: $white;
  }

  &:hover {
    color: $secondary-light;

    &::after {
      background-color: $secondary-light;
    }
  }
}

.text-link--small {
  @include text-link();
  font-size: 15px;

  @include media-breakpoint-up(lg) {
    font-size: 14px;
  }
}

.text-link--small-light {
  @include text-link();
  font-size: 15px;
  color: $white;

  @include media-breakpoint-up(lg) {
    font-size: 14px;
  }

  &::after {
    background-color: $white;
  }

  &:hover {
    color: $secondary-light;

    &::after {
      background-color: $secondary-light;
    }
  }
}

.btn-secondary,
button.btn-secondary,
.btn.btn-secondary {
  @include btn-secondary-is();
}

.btn-secondary--subtle,
button.btn-secondary--subtle,
.btn.btn-secondary--subtle {
  @include btn-secondary-is();
  border-color: rgba(62, 60, 56, 0.45);
}

.btn-pill,
button.btn-pill,
.btn.btn-pill {
  @include btn-pill();
}

.remove-from-wishlist {
  color: $grey3;
}
