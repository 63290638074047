.nav-menu {
  $root: &;

  @media screen and (min-width: $bp-medium-max) {
    background-color: $cultured;
  }

  @media (min-width: $media-breakpoint-desktop) {
    &__items--level-1 {
      > #{$root}__item {
        > #{$root}__item-link {

          &::before {
            background-color: $grey2;
            height: 0.25rem;
          }
        }
      }
    }
  }

  &--slide {
    #{$root}__items--level-1 {
      transition: transform $menu-animations-duration $menu-animations-easing;

      #{$root}__item {
        &-link {
          display: flex;
          background-color: transparent;
          border: 1px solid $grey2;
          border-radius: 0 8px 8px 0;
          align-items: center;
          justify-content: space-between;
          padding: 1rem 0.625rem;

          @include media-breakpoint-down(md) {
            &:hover {
              background: $white;
              color: initial;
              .nav-menu__item-link-name {
                color: initial;
              }
            }
            &:active:not(.nav-menu__item-link--level-3) {
              background: $secondary;
              .nav-menu__item-link-name {
                color: $white;
              }
            }
          }

          .nav-menu__item-link-name {
            letter-spacing: 0;
            flex: 0 0 65%;
            @include media-breakpoint-down(md) {
              padding: 0 10px;
            }
          }

          .mobile-menu-cat-img,
          .mobile-menu-cat-img-alt {
            flex: 0 0 20%;
            max-width: 56px;
          }

          .nav-menu__item-link-chevron {
            flex: 0 0 10%;
          }

          @include media-breakpoint-up(sm) {
            .nav-menu__item-link-name {
              flex: 0 0 80%;
            }

            .mobile-menu-cat-img,
            .mobile-menu-cat-img-alt {
              flex: 0 0 15%;
            }

            .nav-menu__item-link-chevron {
              flex: 0 0 5%;
            }
          }

          @media (min-width: $media-breakpoint-desktop) {
            padding: 0.5rem 0;

            &--level-2,
            &--level-3 {
              padding: 0;
            }
          }

          @include media-breakpoint-up(lg) {
            border: none;
          }
        }
      }
    }

    #{$root}__items--level-2 {
      border: none;
      border-bottom: none;

      @include media-breakpoint-up(lg) {
        background-color: $cultured;
      }

      @media (min-width: $media-breakpoint-desktop) {
        border-width: 0 1px 1px 1px;
        border-style: solid;
        border-color: $border-color;
        border-top: 1px solid $black;
      }

      @include media-breakpoint-up(xl) {
        margin: 0 12.5%;
      }

      .nav-menu__item {
        &-link {
          border: none;

          &-name {
            flex: 0 0 95%;
            color: $black;
          }

          .nav-menu__item-link-name {
            text-transform: uppercase;
          }

          @media (min-width: $media-breakpoint-desktop) {
            border-bottom: none;
          }
        }
      }

      &__title {
        margin: 0.5rem;
        padding: 0.5rem 1.3rem;
      }
    }

    #{$root}__items--level-3 {
      @include media-breakpoint-down(md) {
        #{$root}__item {
          border-bottom: 1px solid $grey2;
          &:last-child {
            border-bottom: 1px solid $grey2;
          }
        }
      }

      .nav-menu__item-link {
        .nav-menu__item-link-name {
          text-transform: none;
          font-weight: normal;
        }

        &:hover,
        &:active {
          .nav-menu__item-link-name {
            color: $secondary;
          }
        }
      }
    }
    @include media-breakpoint-up(lg) {
      .nav-menu__items--level-1 .nav-menu__item-link {
        padding: 0.625rem 0;
        &--level-1 span {
          text-transform: capitalize;
          font-weight: 500;
        }
      }
    }
  }

  &__close-text {
    display: none;
  }

  &__back {
    position: relative;
    border-bottom: rem-calc(1) solid $grey2;
    margin: 0.5rem;

    &-link-text {
      font-size: 16px;
    }
  }

  &__header {
    padding-bottom: 0;
  }

  &__footer {
    display: none;
  }
}

li.nav-menu-item-level-1 {
  @include media-breakpoint-up(md) {
    padding-left: 12px;
    padding-right: 12px;
  }
}
