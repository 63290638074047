.homeBanner {
  @include media-breakpoint-down(xs) {
    overflow-x: hidden !important;
  }
  .marquee {
    @include media-breakpoint-down(xs) {
      -moz-animation: marquee 10s linear infinite;
      -webkit-animation: marquee 10s linear infinite;
      animation: marquee 10s linear infinite;

      @-moz-keyframes marquee {
        0% {
          transform: translateX(10%);
        }

        100% {
          transform: translateX(-200%);
        }
      }

      @-webkit-keyframes marquee {
        0% {
          transform: translateX(10%);
        }

        100% {
          transform: translateX(-200%);
        }
      }

      @keyframes marquee {
        0% {
          -moz-transform: translateX(10%);
          -webkit-transform: translateX(10%);
          transform: translateX(10%);
        }

        100% {
          -moz-transform: translateX(-200%);
          -webkit-transform: translateX(-200%);
          transform: translateX(-200%);
        }
      }
    }
  }
  .marquee:hover {
    animation-play-state: paused;
  }
}
