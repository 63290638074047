.back-to-top {
  transition: transform 0.3s ease-in-out;
  transform: translateY(rem-calc(150));
  bottom: 15.5rem;
  right: 2.1rem;

  &.active {
    transform: translateY(0);
    display: none;
  }
}

[data-action="Search-Show"] {
  .back-to-top.active {
    display: flex;
  }
}
