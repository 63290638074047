@import '../components/productGrid';
@import '../components/searchControls';
@import '../components/searchRefinements';

.search-results,
.search-results-container,
.search-header__content,
.search-header__breadcrumbs {
  max-width: none;
}

.side-col.side-col--left {
  @include media-breakpoint-up(lg) {
    width: 300px;
    max-width: 300px;
  }
}

.filters {
  &__section {
    &__btn {
      color: $grey3;
      font-weight: 500;
      font-size: 15px;
      padding: 16px 0;
      line-height: 100%;

      @media screen and (min-width: $bp-large) {
        border: none;
        padding: 20px 0;
      }
      &-icon {
        margin-top: 1.5rem;

        .font-icon {
          font-size: 13px;
        }
      }
    }
  }

  &__filter-header__icon {
    @include media-breakpoint-down(md) {
      margin-top: 1.125rem;
      margin-right: 0;
    }
  }

  &__filter-header__text {
    @include media-breakpoint-up(lg) {
      font-size: 16px;
      text-transform: none;
    }
  }

  &__form {
    &--price {
      width: 75%;
    }
  }

  &__main-toggle {
    background: none;
  }
}

.search-header {
  &__category {
    display: none;
  }

  &__phrase {
    text-transform: capitalize;
    font-weight: 700;
    color: $accent3;
    text-align: center;
  }
}

.sorting__section__btn {
  border: none !important;
  color: $grey3;
  font-weight: 500;
  line-height: 110%;

  @include media-breakpoint-down(md) {
    padding: 12px 16px !important;
    font-size: 15px !important;
  }

  &-wrapper {
    border: 1px solid var(--Light-grey-2, rgba(0, 0, 0, 0.2));

    &::after {
      display: none;
    }

    @media (min-width: $bp-large) {
      border: none;
      font-size: 16px;
      line-height: 155%;
    }
  }

  &.card-header {
    background-color: $cultured;
  }
}

.search-content {
  .search-results__controls {
    @include media-breakpoint-up(lg) {
      padding-top: 48px;
    }
    padding-bottom: 0;

    .applied-refinements-container {
      .values {
        @include media-breakpoint-down(md) {
          margin-bottom: 8px;
        }
      }
    }
    .has-float-label select {
      background-color: $cultured;
    }
  }
}

.paging__page .icon-chevron-up,
.paging__page .icon-chevron-right {
  font-size: 13px;
}
