$max-width-xl: 1280px;
$bp-xl-min: ($max-width-xl + 1);

@mixin font-pdp-h1() {
  font-family: $font-family-sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  color: $grey3;

  @include media-breakpoint-up(lg) {
    font-size: 1.5rem;
    line-height: 130%;
  }
}

@mixin font-plp-h1() {
  font-family: $font-family-petrona;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.035rem;
  color: $grey3;

  @include media-breakpoint-up(lg) {
    font-size: 2rem;
    letter-spacing: 0.04rem;
  }
}

@mixin font-pdp-h2() {
  font-family: $font-family-petrona;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.03rem;
  color: $grey3;

  @media (min-width: $bp-medium-max) and (max-width: $max-width-xl) {
    font-size: 1.75rem;
  }

  @include media-breakpoint-up(lg) {
    font-size: 2rem;
    letter-spacing: 0.04rem;
  }
}

@mixin font-pdp-h3($color : $grey3) {
  font-family: $font-family-sans-serif;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  color: $color;

  @include media-breakpoint-up(lg) {
    font-size: 1rem;
    line-height: 155%;
  }
}

@mixin font-pdp-paragraph() {
  font-family: $font-family-sans-serif;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: $grey3;
  color: rgba(62, 60, 56, 0.65);

  @include media-breakpoint-up(lg) {
    font-size: 1rem;
    line-height: 155%;
  }
}

@mixin font-pdp-price($color : $grey3) {
  font-family: $font-family-sans-serif;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  color: $color;

  @include media-breakpoint-up(lg) {
    font-size: 1rem;
  }
}

@mixin font-pdp-price-strike-through() {
  font-family: $font-family-sans-serif;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  text-decoration-line: line-through;
  color: $grey41;

  @include media-breakpoint-up(lg) {
    font-size: 1rem;
  }
}

@mixin font-pdp-promotion-label() {
  font-family: $font-family-sans-serif;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  text-transform: uppercase;
  color: $white;
}

@mixin font-pdp-promotion-label-background() {
  width: fit-content;
  display: flex;
  padding: 0.25rem 0.5rem;
  justify-content: center;
  align-items: center;
  background-color: $accent4;
}

@mixin font-pdp-attribute-name() {
  @include font-pdp-h3();
}

@mixin font-pdp-attribute-value() {
  @include font-pdp-paragraph();
  color: $grey3;
}

@mixin font-pdp-text-danger() {
  @include font-pdp-h3($accent1);
}

@mixin font-pdp-product-tile-sticker() {
  font-family: $font-family-sans-serif;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  color: $grey3;

  @include media-breakpoint-up(lg) {
    font-size: 0.75rem;
  }
}

@mixin font-pdp-product-tile-name() {
  font-family: $font-family-sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 155%;
  color: $grey3;
  color: rgba(62, 60, 56, 0.8);

  @include media-breakpoint-up(lg) {
    font-size: 1rem;
  }
}

@mixin font-pdp-product-tile-price($color : $grey3) {
  font-family: $font-family-sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  color: $color;

  @include media-breakpoint-up(lg) {
    font-size: 1rem;
  }
}

@mixin font-pdp-product-tile-price-strike-through() {
  font-family: $font-family-sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  color: $grey3;

  @include media-breakpoint-up(lg) {
    font-size: 1rem;
  }
}

@mixin font-pdp-breadcrumb() {
  font-family: $font-family-sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 300;
  line-height: 110%;
  color: $grey3;
  color: rgba(62, 60, 56, 0.8);
}

@mixin font-pdp-cta-label() {
  font-family: $font-family-sans-serif;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  color: $grey3;

  @include media-breakpoint-up(lg) {
    font-size: 1rem;
  }
}

@mixin font-pdp-pill-btn-label() {
  font-family: $font-family-sans-serif;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  color: $grey3;

  @include media-breakpoint-up(lg) {
    font-size: 0.875rem;
    line-height: 110%;
  }
}

@mixin font-pdp-text-link1() {
  font-family: $font-family-sans-serif;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  color: $grey3;

  @include media-breakpoint-up(lg) {
    font-size: 1rem;
  }
}

@mixin font-pdp-text-link2() {
  @include font-pdp-pill-btn-label();

  @include media-breakpoint-up(lg) {
    line-height: 100%;
  }
}

@mixin font-pdp-text-link3() {
  font-family: $font-family-sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 155%;
  color: $grey3;
  color: rgba(62, 60, 56, 0.8);

  @include media-breakpoint-up(lg) {
    font-size: 1rem;
  }
}

@mixin font-pdp-text-link4() {
  @include font-pdp-breadcrumb();
}

@mixin font-pdp-accordian-title() {
  @include font-pdp-h3();
}

@mixin font-pdp-accordian-body() {
  @include font-pdp-breadcrumb();
  color: rgba(62, 60, 56, 1);
}

@mixin font-d1() {
  font-family: $font-family-sans-serif;
  font-size: 149px;
  font-style: normal;
  font-weight: 300;
  line-height: 110%;
  color: $grey3;
  text-transform: capitalize;
}

@mixin font-d2() {
  font-family: $font-family-sans-serif;
  font-size: 119px;
  font-style: normal;
  font-weight: 300;
  line-height: 110%;
  color: $grey3;
  text-transform: capitalize;
}

@mixin font-d3() {
  font-family: $font-family-sans-serif;
  font-size: 95px;
  font-style: normal;
  font-weight: 300;
  line-height: 110%;
  color: $grey3;
  text-transform: capitalize;
}

@mixin font-d4() {
  font-family: $font-family-sans-serif;
  font-size: 76px;
  font-style: normal;
  font-weight: 300;
  line-height: 110%;
  color: $grey3;
  text-transform: capitalize;
}

@mixin font-d5() {
  font-family: $font-family-sans-serif;
  font-size: 61px;
  font-style: normal;
  font-weight: 300;
  line-height: 110%;
  color: $grey3;
  text-transform: capitalize;
}

@mixin font-h1() {
  font-family: $font-family-sans-serif;
  font-size: 49px;
  font-style: normal;
  font-weight: 300;
  line-height: 110%;
  color: $grey3;
  text-transform: capitalize;
}

@mixin font-h2($color : $grey3) {
  font-family: $font-family-sans-serif;
  font-size: 39px;
  font-style: normal;
  font-weight: 300;
  line-height: 110%;
  color: $color;
  text-transform: capitalize;
}

@mixin font-h2-2() {
  @include font-h2(rgba(62, 60, 56, 0.5));
}

@mixin font-h3() {
  font-family: $font-family-sans-serif;
  font-size: 31px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  color: $grey3;
  text-transform: capitalize;
}

@mixin font-h4() {
  font-family: $font-family-sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 110%;
  color: $grey3;
  text-transform: capitalize;
}

@mixin font-h5() {
  font-family: $font-family-sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 110%;
  color: $grey3;
  text-transform: capitalize;
}

@mixin font-h6() {
  font-family: $font-family-sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  color: $grey3;
  text-transform: capitalize;
}

@mixin font-b1($color : $grey3) {
  font-family: $font-family-sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: $color;
}

@mixin font-b2($color : $grey3) {
  font-family: $font-family-sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: $color;
}

@mixin font-b3($color : $grey3) {
  font-family: $font-family-sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: $color;
}

@mixin font-b4($color : $grey3) {
  font-family: $font-family-sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  color: $color;

  @include media-breakpoint-up(lg) {
    font-size: 1rem;
  }
}

@mixin font-b5($color : $grey41) {
  font-family: $font-family-sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  color: $color;
  text-decoration-line: line-through;

  @include media-breakpoint-up(lg) {
    font-size: 1rem;
  }
}

@mixin font-b6($color : $accent4) {
  font-family: $font-family-sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  color: $color;
  text-transform: uppercase;
}

@mixin font-list-item($color : $grey3) {
  font-family: $font-family-sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: $color;

  @include media-breakpoint-up(lg) {
    font-size: 1rem;
    line-height: 155%;
  }
}

@mixin font-pdp-text-link-std($color : $grey3) {
  font-family: $font-family-sans-serif;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  color: $color;
  text-decoration-line: underline;
  text-underline-offset: 0.25rem;

  @include media-breakpoint-up(lg) {
    font-size: 1rem;
  }
  &:hover {
    color: $secondary;
  }
}

@mixin font-main-nav($color : $grey3) {
  font-family: $font-family-sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  color: $color;

  @include media-breakpoint-up(lg) {
    font-size: 1rem;
    line-height: 100%;
  }
}

@mixin font-search-field($color : $grey3) {
  font-family: $font-family-sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  color: $color;

  @include media-breakpoint-up(lg) {
    font-size: 1rem;
    line-height: 110%;
  }
}

@mixin font-search-field($color : $grey3) {
  font-family: $font-family-sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  color: $color;

  @include media-breakpoint-up(lg) {
    font-size: 1rem;
    line-height: 110%;
  }
}

@mixin font-search-placeholder() {
  @include font-search-field(rgba(62, 60, 56, 0.4));
}

@mixin font-top-banner($color : $white) {
  font-family: $font-family-sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  color: $color;
}
