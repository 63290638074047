.contact-us {
  &__header {
    img.hidden-md-down + h4 {
      @include media-breakpoint-up(lg) {
        color: $white;
        position: absolute;
        top: calc(50% - #{$h4-font-size / 2});
        left: 0;
        right: 0;
        margin: 0 auto;
        text-align: center;
      }
    }
  }

  &__content {
    padding-right: map-get($spacers, 1);
  }

  &__form {
    padding-left: map-get($spacers, 1);
  }
}
