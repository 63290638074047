.account-heading,
.account-pages-heading {
  color: $grey3;
}

.change-password-description,
.change-password-disclaimer {
  color: $black;
}

.wishlist__send-email {
  display: none;
}
