$media-breakpoint-desktop-large: 1439px;

.header {
  max-width: none;

  .header-items {
    padding-top: 8px;
    @media only screen and (min-width: $md-width) {
      padding-left: 4.5rem;
      padding-right: 4.5rem;
    }

    @media only screen and (max-width: $md-width) {
      padding-left: 3.5rem;
      padding-right: 3.5rem;
    }

    @media only screen and (max-width: $bp-medium-max) {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }

    @media only screen and (max-width: $bp-small-max) {
      padding-left: 0;
      padding-right: 0;
    }

    @include media-breakpoint-down(md) {
      padding-bottom: 0;
    }
  }

  &-element {
    &.logo.left {
      display: none !important;
    }

    &.logo.center {
      position: relative;
      margin-left: 0;
      margin-right: 0;
      display: block !important;
      @include make-col(3);

      @include media-breakpoint-up(xl) {
        top: 1rem;
      }
      .navbar-header {
        display: inline-block;
        padding-left: 0 !important;
        .header-image {
          height: 56px;
          @media (max-width: $bp-medium-max) {
            height: 42px;
          }
        }
      }
    }
  }

  .header-item {
    &__search {
      position: unset;
      padding: 6px 16px 0 16px;
      @media (min-width: $bp-medium) {
        order: 2;
        margin-bottom: 0.5rem;
        @include make-col(6);
      }

      @media only screen and (max-width: $bp-small-max) {
        margin-bottom: 0;
        order: 3;
        flex: 0 0 100%;
        max-width: 100%;
      }
    }

    .site-search {
      font-size: 16px;
    }

    &__menu {
      @media only screen and (min-width: $bp-medium) {
        display: none !important;
      }
    }
  }

  .right-container {
    padding-right: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include make-col(3);

    @media only screen and (max-width: $bp-small-max) {
      order: 2;
      flex: 0 0 50%;
      max-width: 50%;
      padding-right: 16px;
    }

    margin-right: 0;
    margin-left: auto;
    order: 3;

    @include media-breakpoint-up(lg) {
      @include make-col(3);
    }

    @include media-breakpoint-up(xl) {
      top: 1rem;
      position: relative;
    }
  }

  .header-element {
    margin-right: 0;
    line-height: 100%;

    .font-icon {
      color: $accent3;
    }

    &.header-element__menu {
      margin-left: 1rem;
      padding-right: 1rem;
    }

    &.minicart {
      padding: 10px;
      .minicart-link {
        position: relative;
        &--active {
          .font-icon {
            color: $secondary;
          }
        }
      }

      .minicart-round-element {
        position: absolute;
        height: 20px;
        width: 20px;
        border-radius: 66px;
        background-color: #07949f;
        left: 10px;
        bottom: -11px;
        border: 2px solid #faf8f5;
        display: flex;
        align-items: center;
      }

      .minicart-quantity {
        display: inline-block;
        width: 18px;
        text-align: center;
        font-weight: 500;
        line-height: 100%;
        font-size: 10px;
        color: #faf8f5;
      }

      .minicart-label {
        display: none;
      }

      @media (min-width: $media-breakpoint-desktop) {
        .minicart-link {
          display: flex;
          align-items: center;

          .minicart-icon__container {
            order: 2;

            .minicart-icon {
              margin-left: 0;
              margin-bottom: map-get($spacers, 1);
            }
          }
        }
      }
    }

    &__menu {
      .nav-menu__link__text {
        display: none;
      }
    }

    @include media-breakpoint-only(md) {
      &.user {
        .font-icon {
          font-size: rem-calc(33);
        }
      }
    }

    @media (min-width: $media-breakpoint-desktop) {
      &__icon:not(.minicart-icon__container) {
        display: inline-block;
        padding-top: 0;

        img {
          height: 20px;
          width: 24.29px;
        }
      }

      &__icon.minicart-icon__container {
        padding-top: 0;
      }
    }

    @media (min-width: $media-breakpoint-desktop) {
      &__icon {
        &.minicart {
          order: 3;
          margin-left: map-get($spacers, 1);
        }
      }
    }

    .header-account-image {
      &:hover {
        svg path {
          fill: $grey2;
        }
      }

      &:active {
        svg path {
          d: path('M15.0042 10.8543C16.7947 9.8477 17.999 7.96907 17.999 5.81769C17.999 2.60467 15.3129 0 11.9995 0C8.68604 0 5.99998 2.60467 5.99998 5.81769C5.99998 7.9692 7.2044 9.84793 8.99509 10.8545C3.8219 12.3633 0 17.6768 0 24H23.9999C23.9999 17.6765 20.1777 12.3629 15.0042 10.8543Z');
          fill: $black;
        }
      }
    }

    &.stores {
      display: none;
    }

    &__wishlist {
      padding: 10px;
      margin-right: 4px;
      display: block;
    }
  }

  .search-icon-wrapper {
    border-left: none;

    &:hover,
    &:active {
      border-left: none;
    }

    .icon-fa-search {
      color: $accent3;
      font-size: 1rem;
    }
  }
  .scroll-down {
    top: 0;
  }
}

.js-page-locale-loader .country-selector-link {
  display: none;
}

#countryGateway {
  color: $accent3;
  .country-selector__form__field .btn {
    @include btn-primary-small();
  }
}

.nav-menu {
  &__body {
    .nav-menu {
      &__items {
        &--level-1 {
          border: none;

          @media (min-width: $media-breakpoint-desktop) {
            .nav-menu__item a {
              padding-top: 0;
            }
          }
        }
      }

      &__content--side {
        margin-top: 2rem;
        @media (min-width: $media-breakpoint-desktop) {
          display: inline-block;
          margin-top: 0;
        }
        @include media-breakpoint-down(md) {
          padding-right: 10px;
        }
      }
    }
  }

  &__item-link-name {
    font-weight: 700;
    font-size: 16px;
    line-height: 110%;
    color: $accent3;
  }

  &__search {
    display: none;
  }
}

.header-sticky {
  background-color: $cultured;
  border-bottom: 1px solid $border-light-grey;
  @include media-breakpoint-up(lg) {
    border-bottom: 1px solid $border-grey;
  }
  &.scroll-down {
    opacity: 1;
  }
}

input[type="search"]::placeholder {
  color: $grey2 !important;
}

header {
  &.js-is-sticky,
  &.js-is-stuck {
    .header {
      .header-items {
        @media (min-width: $media-breakpoint-desktop) {
          padding-top: 0;
          padding-bottom: 0;
        }
      }

      .search-field {
        @media only screen and (min-width: $bp-medium) {
          border-bottom: 1px solid $grey3;
        }
      }
    }
  }
}

@mixin is-frame-up() {
  border-width: 3.90538px 3.90538px 0 3.90538px;
  border-style: solid;
  border-color: $accent2;
  padding: 0.1rem calc(100% - 18.5rem);
}

@mixin is-frame-down() {
  @include is-frame-up();

  border-width: 0 3.90538px 3.90538px 3.90538px;
}

.header-banner {
  background-color: $accent3;
  color: $white;
}

.primary-category-slider {
  display: none;
  max-height: 40px;

  @media only screen and (max-width: $bp-medium-max) {
    margin: rem-calc(13) rem-calc(40) rem-calc(13) rem-calc(40);
    display: block;
  }

  @media only screen and (max-width: $bp-small-max) {
    margin: 0;
    margin-top: rem-calc(13);
    padding: 0 0 rem-calc(13) 1rem;
  }

  li {
    font-size: 14px;
    line-height: 1;
  }

  span {
    text-transform: capitalize;
    @include font-main-nav();
    @include media-breakpoint-up(md) {
      margin-right: 20px;
    }
  }
}

.header-item__search {
  padding: 0 16px;
  position: unset;
  @include media-breakpoint-down(xs) {
    top: rem-calc(32);
  }
  &.lower {
    display: none !important;
  }
}

.inner-container {
  align-items: baseline;
  .user {
    order: -1;
    padding: 10px;
    margin-right: 4px;
  }
}

.header .header-element.minicart .minicart-total {
  @include media-breakpoint-up(lg) {
    margin-left: 0;
  }
}
