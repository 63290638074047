@import "components/homeBanner";
@import "components/heroCarousel";
@import "components/heroProduct";
@import "components/categoryCarousel";

.cat-landing-component {
  background-color: $accent3;
  padding: 20px;
  flex-direction: column;

  @include media-breakpoint-up(sm) {
    flex-direction: row;
  }

  .headlinebanner-text_container {
    border: 3.90538px solid;
    padding: 1.5rem 0;
    height: auto;
    // margin: auto;
    // margin-bottom: 20px;

    @include media-breakpoint-up(sm) {
      margin-bottom: unset;
      flex: 0 0 45%;
    }

    @media (min-width: $media-breakpoint-desktop) {
      flex: 0 0 33%;
    }

    @include media-breakpoint-up(xl) {
      flex: 0 0 50%;
      padding-right: 0.5rem;
    }

    .headlinebanner-text {

      &_heading {
        text-transform: capitalize;
      }
    }

    .headlinebanner-text-border {
      position: relative;
      right: 0.7rem;

      @include media-breakpoint-up(xl) {
        right: 0.3rem;
      }
    }

    .show-read-more {
      .more-text {
        display: none;
      }

      .read-more {
        display: block;
        padding: 0.5rem 0;
      }
    }
  }

  .banner-image {
    padding-left: 0;
    margin-top: 20px;

    @include media-breakpoint-up(sm) {
      padding-left: 16px;
      flex: 0 0 55%;
      margin-top: 0;
    }

    @media (min-width: $media-breakpoint-desktop) {
      flex: 0 0 67%;
    }

    @include media-breakpoint-up(xl) {
      flex: 0 0 50%;
    }

    img {
      width: -webkit-fill-available;
      height: 181px;
      object-fit: cover;

      @include media-breakpoint-up(sm) {
        height: 244px;
      }

      @include media-breakpoint-up(xl) {
        height: 181px;
      }
    }
  }
}

.experience-assets-catogoryLinks {
  overflow: scroll;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
  }

  .category-links {
    width: max-content;
    margin: auto;

    .category {
      margin: 1.5rem 0.75rem 1rem;
      width: fit-content;

      a {
        border-radius: 100px;
        padding: 0.7rem 1rem;
        text-transform: capitalize;
      }
    }
  }
}
