.breadcrumb {
  @include media-breakpoint-up(sm) {
    justify-content: left;
  }

  .breadcrumb-item {
    a,
    &::before {
      color: $grey3;
      font-weight: 300;
      font-size: 14px;

      &:hover {
        color: $secondary;
      }
    }
  }
}

.product-detail .product-breadcrumb.product-detail__nav-row__breadcrumbs,
.search-header__breadcrumbs__container {
  .breadcrumb-container {
    .breadcrumb {
      background-color: $cultured;
      @include media-breakpoint-down(sm) {
        justify-content: left !important;
        flex-wrap: nowrap;
        white-space: nowrap;
        overflow: auto;
        &::-webkit-scrollbar {
          display: none;
        }
      }

      @media only screen and (max-width: $md-width) {
        padding: 1.75rem 3.5rem;
      }

      @media only screen and (max-width: $bp-large) {
        padding: 1.75rem 2.5rem;
      }

      @media only screen and (max-width: $bp-small-max) {
        padding: 0.75rem 1rem;
      }

      @media only screen and (min-width: $md-width) {
        padding: 1.75rem 4.5rem;
      }

      .breadcrumb-item + .breadcrumb-item {
        padding-left: 0;
      }

      .breadcrumb-item {
        font-size: 14px;
        line-height: 100%;
        &::before {
          content: url('../../../../static/default/images/breadcrumb_split.svg');
          padding: 0 0.625rem;
        }
        &:first-child {
          &::before {
            display: none;
            margin-left: 0;
          }
          a {
            margin-left: 0;
          }
        }
        a {
          margin-left: -5px;
          @include font-pdp-breadcrumb();
          &:hover {
            color: $secondary;
          }
        }
      }
    }
  }
}

.product-breadcrumb.product-detail__nav-row__breadcrumbs .breadcrumb-container {
  padding: 0 0 0 8px;
}

.search-header__breadcrumbs__container .breadcrumb-container {
  padding: 0 0 0 0;
}
