.saved-payments-list {
  &__edit,
  &__delete {
    @include text-link();
  }
  &__delete {
    color: $grey3;
    &__icon {
      display: none;
    }
  }
  &__edit,
  &__separator {
    display: none;
  }

  color: $grey3;
  .form-row {
    label {
      color: $grey3;
      font-weight: 700;
    }
  }

  &__card-type {
    font-weight: 700;
  }
}
.payment-form {
  input,
  select {
    color: $accent3;
    border-color: $accent3;
    &::placeholder {
      color: $accent3;
    }
  }
  label {
    color: $accent3;
  }
  button,
  .btn {
    &.btn-secondary {
      @include btn-secondary-override();
    }
  }
}
