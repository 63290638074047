@import "../components/checkout/progressIndicator";
@import "../checkout/checkoutLogin";
@import "../components/checkout/pages/shipping";
@import "../components/checkout/pages/billing";

.checkout-header {
  border-bottom: 1px solid $black !important;

  .logo {
    max-width: rem-calc(170);

    @include media-breakpoint-up(lg) {
      max-width: none;
    }
  }

  &__logo {
    flex: 0 0 40%;
  }

  &__title {
    flex: 0 0 60%;
    margin-right: 0;
    margin-left: auto;
  }

  &__payment {
    flex: 0 0 100%;
    justify-content: center;
  }

  @include media-breakpoint-up(sm) {
    &__logo {
      flex: 0 0 10%;
    }

    &__title {
      flex: 0 0 27%;
    }

    &__payment {
      flex: 0 0 44%;
      justify-content: flex-end;
    }
  }

  .header-secure-checkout {
    color: $accent3;

    span {
      position: relative;
      top: 0.4rem;
    }
  }
}

#checkout-footer {
  border-top: none;

  li {
    text-decoration: none;
    padding: 0.2rem;

    @include media-breakpoint-up(sm) {
      width: auto;
      margin: 0 1rem;
    }

    @include media-breakpoint-up(lg) {
      margin: 0 2rem;
    }
  }
}

.checkout-step {
  &__heading {
    display: none;
  }

  &__errors {
    margin-bottom: 0 !important;
  }

  &__content {
    .address-summary {

      @include media-breakpoint-up(md) {
        padding-left: 0;
      }
    }
  }
}

.checkout {
  .summary {
    border: none;
    &__container {
      margin-top: 1.5rem;
    }

    &__totals-container {
      font-weight: 700;
    }
  }

  &__content-wrapper {
    margin-top: 0 !important;

    button.btn-primary {
      @include btn-primary-large();
      width: 100%;

      img {
        flex: 0 0 3%;
      }

      div {
        flex: 0 0 97%;
      }
    }
  }
}
