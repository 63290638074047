.glide__arrows {
  .glide__arrow {
    top: 50%;
    transform: translateY(-50%);
    color: $accent3;
    background-color: $white;
    border-width: 1px;
    border-radius: 0;
    border: 1px solid $white;
    background-repeat: no-repeat;
    padding: 20px;
    background-position: center;
    @include media-breakpoint-down(md) {
      display: none;
    }

    &:hover {
      color: $accent3;
      background-color: $grey1;
      border: 1px solid $grey2;
      opacity: 1;
    }

    &:active {
      background-color: $grey3;
      border: 1px solid $black;
      color: $white;
    }
  }

  .glide__arrow--left {
    left: 0;
    background-image: url('../../../../static/default/images/icons/prev.svg');
    &:hover {
      opacity: 1;
    }
  }

  .glide__arrow--right {
    right: 0;
    background-image: url('../../../../static/default/images/icons/next.svg');
    &:hover {
      opacity: 1;
    }
  }
}
