.minicart {
  &__header {
    &__heading {
      @include make-col(9);
      h6 {
        font-size: 1.25rem;
      }
    }
    &__close-icon {
      @include make-col(3);
    }
  }

  &__notes {
    font-size: 13px;
    line-height: initial;
  }

  &__checkout {
    .checkout-btn {
      @include btn-primary-is();
    }
  }
}
