/**
 * Main SCSS file
 * Serves as an entrypoint for the common styling,
 * site-specific cartridges are expected to import and override it
 */

$bp-medium: 768px !default;
$bp-large: 992px !default;
$bp-xlarge: 1200px !default;
$bp-small-max: ($bp-medium - 1) !default;
$bp-medium-max: ($bp-large - 1) !default;
$bp-large-max: ($bp-xlarge - 1) !default;
$lg-path-images: '../images' !default;

:export {
  // stylelint-disable property-no-unknown
  breakpoint-sm-max: $bp-small-max;
  breakpoint-md-min: $bp-medium;
  breakpoint-md-max: $bp-medium-max;
  breakpoint-lg-min: $bp-large;
  breakpoint-lg-max: $bp-large-max;
  breakpoint-xl-min: $bp-xlarge;
  // stylelint-enable property-no-unknown
}

@import "variables";
@import "bootstrap/scss/bootstrap";
@import "bootstrapOverrides";
@import "font-awesome/scss/font-awesome";
@import "flag-icon-css/sass/flag-icon";
@import "zIndexes";

@import "fonts";
@import "utils/sizing";
@import "utils/responsive";
@import "utils/mixins";
@import "utils/icons";
@import "utils/print";
@import "components/headers";
@import "components/common";
@import "components/forms";
@import "components/buttons";
@import "components/spinner";
@import "components/addressForms";
@import "components/header";
@import "components/headerSearch";
@import "components/countrySelector";
@import "components/footer";
@import "components/miniCart";
@import "components/breadcrumbs";
@import "components/password";
@import "components/gridSizeSelector";
@import 'components/backToTop';
@import "components/accordion";
@import "components/orderContent";
@import "components/menu";
@import "components/modal";
@import "components/slideToggle";
@import "components/shippingSummary";
@import "components/newsletterOptIn";
@import "pages/cart";
@import "pages/dashboard";
@import "pages/checkout";
@import "pages/login";
@import "pages/trackOrder";
@import "pages/editProfile";
@import "pages/preferenceCenter";
@import "pages/savedPayments";
@import "pages/orderHistory";
@import "pages/search";
@import "pages/contactUs";
@import "pages/appointments";
@import "pages/addresses";
@import "pages/wishlist";
@import "pages/content";
@import "pages/confirmation";
@import "pages/reviews";
@import "pages/productDetail";
@import "pages/storeLocator";
@import "pages/newsletter";
@import "pages/error";
@import "@glidejs/glide/src/assets/sass/glide.core";
@import "@glidejs/glide/src/assets/sass/glide.theme";
@import "pages/addToBagOverlay";
