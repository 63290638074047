$form-control-border-color: $accent3 !default;

input[placeholder],
input::placeholder,
textarea::placeholder {
  color: $accent3;
}

.country-dial-code {
  .down-arrow-newsletter {
    top: 15px;
    left: 5.5rem;
  }
  .down-arrow-profile-form,
  .down-arrow-address-form {
    top: 10px;
  }
}
.intl-tel-input {
  display: block;
  .flag-container {
    top: 12px;
    .iti-flag {
      margin-top: 9px;
    }
  }
  .separate-dial-code {
    .selected-dial-code {
      top: 6px;
    }
  }
}
.form-control {
  height: $form-control-height;
  background-color: $white;
  border-radius: 0;

  &.is-valid,
  &.is-invalid {
    &:focus {
      box-shadow: none;
    }
    ~ .invalid-feedback {
      letter-spacing: 0;
    }
  }

  &:focus {
    box-shadow: none;
    outline: 0;
  }
}

.registration__form {
  .form-group {
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
}
.address-form {
  color: $grey3;

  input,
  select {
    color: $accent3;
    border-color: $accent3;

    ::placeholder {
      color: $accent3;
    }
  }

  .form-group {
    label {
      color: $grey3;
    }
    &--checkbox,
    .input-checkbox {
      font-weight: 700;
    }
  }

  .country-dial-code {
    border-color: $grey3;
  }

  .required-form {
    color: $accent3;
    font-size: 13px;
  }
}

.has-float-label {
  label {
    color: $black;
  }

  &.form-row-radio {
    .radio-box {
      label {
        &::before {
          border: 2px solid $black;
        }

        &::after {
          border-color: $grey3;
        }
      }
    }
  }
}

.required-form {
  color: $accent3;
}

.address-lookup__title {
  font-weight: 700;
  font-size: 20px;
  line-height: 110%;
  letter-spacing: -0.05em;
}

.checkout-shipping__method-content,
.checkout-billing__payment-selector {
  &::before {
    border: 2px solid $black;
  }

  &::after {
    border-color: $grey3;
  }
}

input,
input[type="text"],
input[type="email"],
input[type="tel"],
input[type="time"],
input[type="number"],
input[type="date"],
input[type="password"],
select,
textarea,
input.form-control {
  letter-spacing: 0;
  line-height: 110%;
}
