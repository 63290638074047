.confirmation {
  color: $accent3;
  &__logout-container {
    &__link {
      @include media-breakpoint-up(lg) {
        @include make-col(3);
      }
    }
  }
  &.checkout-shipping__type-link {
    border-color: $grey2;
  }

  form {
    input,
    select {
      color: $accent3;
      border-color: $accent3;
      &::placeholder {
        color: $accent3;
      }
    }

    label {
      color: $accent3;
    }
    .form-row-checkbox label {
      color: $accent3;
      font-weight: 700;
    }
  }
  .checkout__registration--options {
    p,
    span {
      color: $accent3;
    }
  }

  button,
  .btn {
    &.btn-secondary {
      @include btn-secondary-override();

      &.confirmation__logout-container__link {
        background-color: $white;
        &:hover {
          background-color: $white;
        }
      }
    }
  }

  .order-content__order-number {
    font-weight: 700;
    font-size: 20px;
    line-height: 110%;
    letter-spacing: -0.05em;
    text-transform: capitalize;
  }
}

.confirmation__order-summary-title {
  color: $accent3;
}

.order-content {
  color: $accent3;
  .summary {
    &__products {
      &-heading {
        border-color: $accent3;
        padding-top: 1.5rem !important;
      }
      &-container {
        border-color: $accent3;
      }
    }
  }
  &__right--container {
    border-color: $grey2;
  }
  &__print-btn {
    @include btn-secondary-is();
  }
  &__footer {
    color: $accent3;
  }
  .order-details {
    &__summary-item {
      &--shipping-address,
      &--billing-address {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.order-content__container {
  button,
  .btn {
    &.btn-secondary {
      @include btn-secondary-override();
    }
  }

  .order-details__summary-item {
    line-height: 110%;
    text-transform: capitalize;
  }
}
