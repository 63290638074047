$cultured-one: rgba(23, 23, 24, 0.3);

.site-search {
  input {
    &.form-control {
      border: none;
      border-bottom: 1px solid $cultured-one !important;
      background-color: transparent;
      letter-spacing: 0;
      line-height: 110%;
      padding: 12px 0 12px 32px;
      height: 40px;
      @media only screen and (max-width: $bp-small-max) {
        border: 1px solid $cultured-one !important;
      }

      &::placeholder {
        color: gba(62, 60, 56, 0.4) !important;
      }
    }
  }

  &__controls {
    .clear-search {
      color: $black;

      &__icon {
        display: inline;
        position: absolute;
        right: 0;
        font-size: 16px;
        font-weight: bold;
        color: $accent3;
      }
    }

    .search-button {
      right: unset;
      top: -34px;
    }
  }
}

.suggestions {
  border: 1px solid $grey2;
  top: 2rem;
  background-color: $white;

  @include media-breakpoint-up(sm) {
    background-color: $white;
    width: rem-calc(755);
    left: calc(100% - 538px);
    top: 3.625rem;
  }

  @media (min-width: $media-breakpoint-desktop) {
    left: calc(100% - 587px);
  }

  &-container {
    @include media-breakpoint-up(sm) {
      flex-wrap: nowrap;
    }
  }

  &__content {
    @include media-breakpoint-up(sm) {
      @include make-col(3);
    }
  }

  .close-search {
    display: none;
  }

  .arrow-up {
    display: none;
  }

  ul {
    li {
      a {
        color: $accent3;
      }
    }
  }

  &__products {
    @include media-breakpoint-up(sm) {
      display: block;
    }

    .items {
      @include media-breakpoint-up(sm) {
        @include make-col(3);
      }
      .product {
        &-tile {
          font-size: 13px;
          color: $accent3;
          &__name,
          &__price {
            .link,
            span {
              color: $accent3;
              font-weight: normal;
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}
