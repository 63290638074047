$button-height: rem-calc(40);
$button-letter-spacing: rem-calc(5.6);
$button-font-size: rem-calc($fontsize-medium);
$button-line-height: 110%;

$button-primary-bg: $secondary;
$button-primary-color: $accent2;
$button-primary-border: $secondary;
$button-primary-hover-bg: $primary-cta-hover;
$button-primary-hover-color: $accent2;
$button-primary-hover-border: $primary-cta-hover;
$button-primary-active-bg: $primary-cta-active;
$button-primary-active-color: $accent2;

$button-secondary-bg: $secondary1;
$button-secondary-color: $accent2;
$button-secondary-border: $secondary1;
$button-secondary-hover-bg: $secondary1;
$button-secondary-hover-color: $secondary;
$button-secondary-hover-border: $button-secondary-hover-bg;
$button-secondary-active-bg: $secondary-cta-active;
$button-secondary-active-color: $accent2;
$button-secondary-active-border: $button-secondary-active-bg;
