@import "../components/accountNav";
@import "../components/accountHeading";

.account-container {
  .account-heading {
    text-align: center;

    @include media-breakpoint-up(md) {
      text-align: left;
    }
  }

  .account-pages-heading {
    &__text {
      @include h3;

      text-align: center;
    }
  }
  .account-pages-copy {
    text-align: center;
  }
  .has-float-label {
    label {
      color: $primary;
    }
  }
  .account-dashboard-content {
    h5 {
      color: $grey3;
    }
    p {
      color: $grey3;
    }
  }
}
