.checkout {
  &__progress,
  &__progress-step {
    &::before,
    &::after {
      display: none;
    }

    &-content {
      color: $grey2;
      font-weight: 700;
      font-size: 25px;
    }

    &--current {
      .checkout__progress-step-content {
        color: $accent3;
        border-bottom: 2px solid $black;
      }

      .checkout__progress-step-number {
        color: $accent3;
        background-color: transparent;
      }
    }

    &--completed {
      .checkout__progress-step-number {
        color: $grey2;
        background-color: transparent;
      }
    }

    &--submitted {
      display: none;
    }
  }

  &__progress {
    margin: 2rem 0;
    padding: unset;
  }

  &__progress-step-number,
  &__progress-step-name {
    display: inline-block;
    border: none !important;
  }

  &__progress-step-number {
    width: fit-content;
    padding-right: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
