.product-card {
  &__image {
    overflow: hidden;
    width: 85px;
    object-fit: cover;
    aspect-ratio: 1; // stylelint-disable-line
  }

  &__content {
    &__heading {
      font-weight: bold;
    }
  }

  &__price {
    &__promo {
      margin-top: map-get($spacers, 2);
    }

    .has-strike-through {
      font-weight: normal;
    }
  }

  &__link,
  &__attribute,
  &__price {
    color: $accent3;
    display: block;

    &--size {
      border: none;
    }
  }
}
