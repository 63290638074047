.saved-addresses {
  &__card {
    @include media-breakpoint-up(md) {
      @include make-col(4);
    }

    &-wrapper {
      color: $grey3;

      .form-row-checkbox label {
        color: $grey3;
        font-weight: 700;
      }
    }

    &-actions {
      a {
        @include text-link();
      }
    }
  }

  &__edit {
    color: $secondary;
  }

  &__add-address-btn {
    text-decoration: none;
  }
}

.save-address {
  &__form-wrapper {
    text-align: left;

    @include media-breakpoint-up(lg) {
      @include make-col(6);
    }

    button,
    .btn {
      &.btn-secondary {
        @include btn-secondary-override();
      }
    }
  }
  .address-lookup__body {
    background-color: #d7d7d5;
  }
}
