.checkout-login {
  .card {
    border: none;

    .card-body {
      .description h6 {
        font-size: 20px;
        text-transform: capitalize;
      }

      button {
        @include btn-primary-large();

        text-align: left;

        div {
          flex: 0 0 95%;
          text-align: center;
          padding-right: 5%;
        }
      }
    }
  }

  .page-title {
    font-size: 31px;
    text-align: left;
    margin-left: 0;

    @include media-breakpoint-up(sm) {
      margin-left: 3rem;
    }
  }

  .signing-in-msg {
    color: $black;
  }

  &--existing.checkout-login {
    .card {
      .card-body button {
        text-align: center;
      }
    }

    .checkout-as-guest {
      @include btn-secondary-is();
    }
  }
}
