h1,
.h1 {
  @include font-h1();
}

h2,
.h2 {
  @include font-h2();
}

h3,
.h3 {
  @include font-h3();
}

h4,
.h4 {
  @include font-h4();
}

h5,
.h5 {
  @include font-h5();
}

h6,
.h6 {
  @include font-h6();
}

.B1-reg,
.B1-bold {
  @include font-b1();
}

.B2-reg,
.B2-bold {
  @include font-b2();
}

.B3-reg,
.B3-bold {
  @include font-b3();
}

.B1-bold {
  font-weight: 500;
}

.B2-bold {
  font-weight: 600;
}

.B3-bold {
  font-weight: 700;
}

.d1 {
  @include font-d1();
}

.d2 {
  @include font-d2();
}

.d3 {
  @include font-d3();
}

.d4 {
  @include font-d4();
}

.d5 {
  @include font-d5();
}
