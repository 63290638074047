$icon-font: 'InsideStoryIconFont';
$font-family-sans-serif: 'Funkis', sans-serif;
$font-family-petrona: 'Petrona', sans-serif;
@import '~core-css/variables';

$accent1: #e15200;
$accent2: #fffdee;
$accent3: #5b514f;
$accent4: #b82d2d;
$promo-red: #91251e;

$cultured: #faf8f5;

$primary: $accent3;
$secondary: #01959f;
$secondary1: #6bcaba;
$disabled: #f0ede9;
$disabled-active: #fee1d1;

$grey1: #d7d7d5;
$grey2: #c8c2ba;
$grey3: #3e3c38;
$grey41: #807b73;
$grey51: #73726e;

$info: #c5d4e5;
$success: #3cb371;
$alert: #ec8100;
$error: #c1281b;

$accent1-light: #f9dccc;
$accent2-light: #fffdf6;
$accent2-lighter: #fffef8;
$secondary-light: #e1f4f1;

$grey1-disabled: #e9e9e8;
$grey2-hover: #d6d2cc;
$grey2-background: #f4f3f1;
$accent3-hit: #8a8381;
$accent1-hover: #ea864d;
$accent1-hit-state: #c64800;

$primary-cta-active: #028791;
$primary-cta-hover: #04b0bc;

$secondary-cta-active: #01828b;
$secondary-light: #e5feff;

$fontsize-medium: 16px;

$grey1-background: #d7d7d54d;

$media-breakpoint-desktop: 1024px;

$border-grey: rgba(62, 60, 56, 0.2);
$border-light-grey: rgba(62, 60, 56, 0.1);
