a.filters__value-link.filters__value-link--boolean::before {
  display: inline-block;
}

.category-homewares-filter-size {
  flex: 0 auto;
  .filters__value--size {
    display: block;
    width: fit-content;
    margin-bottom: 8px;
    margin-right: 8px;
    border-radius: 100px;
    border: 1px solid rgba(0, 0, 0, 0);
    background: #f0ede9;
    padding: 12px 16px;

    .filters__value-text {
      border: 0;
      padding: 0;
      text-transform: none;
      &--not-one-size {
        width: fit-content;
      }
    }
  }
}

.filters {

  #filters-toggle {
    .icon-chevron-up,
    .icon-plus {
      &::before {
        display: block;
        font-size: 15px;
        top: 3.5px;
        position: relative;
        content: url('../../../../static/default/images/filters_icon.svg');

        @media (min-width: $bp-large) {
          bottom: 2px;
          top: 0;
        }
      }
    }

    .filters__toggle-section__btn-icon {
      margin-top: 0.6rem;

      @media (min-width: $bp-large) {
        margin-top: 1.5rem;
      }
    }
  }

  &__filter-body {
    .values {
      width: 100% !important;
      @include media-breakpoint-down(md) {
        margin-bottom: 8px;
      }
    }
  }

  &__body {
    background-color: $cultured;
  }

  &__heading {
    text-align: left !important;
    padding-left: 16px;
    position: fixed;
    width: 100%;
    z-index: 10;
    top: 0;
    background-color: $white;
    left: 100%;
    transition: left 0.3s ease-in-out;
  }

  &__items {
    padding-top: 73px;
  }

  &__section {
    text-transform: none !important;
    background-color: $cultured;
  }

  &__section__btn {
    line-height: 16.5px;
    padding: 16px 0;
    font-size: 15px;
    font-weight: 500;
    background-color: $cultured;
  }

  &-container {
    padding-bottom: 13.25rem;
    background-color: $cultured;
    #filters {
      background-color: $cultured;
      .filters__heading {
        background-color: $white;
      }
    }
    &.show {
      .filters__heading {
        left: 0;
      }
    }
  }

  @media (min-width: $media-breakpoint-desktop) {
    &__main-toggle {
      margin-right: 2.5rem;
    }

    &__footer {
      background-color: $white;
      .filters__reset {
        display: none;
      }
    }
  }

  &__value-link {
    display: flex;
    position: relative;

    &::before {
      border-color: $grey1;
      margin-right: 8px;
    }

    &.selected {
      &::before {
        width: 16px;
        height: 16px;
        padding: 0;
        color: $white;
        border-color: $grey3;
        background-color: $cultured;
        border: none;
        content: url('../../../../static/default/images/filter_checked.svg');
      }
    }

    &.disabled {
      &::before {
        display: inline-block;
        border: none;
        content: url('../../../../static/default/images/filter_cross.svg');
      }
    }
  }

  &__value-text {
    font-size: 14px;
    font-weight: 500;
    line-height: 100%;
    color: $grey3;
  }

  &__value {
    border: 0;
    padding-top: 0;
    text-align: left;

    @include media-breakpoint-up(lg) {
      margin-bottom: 16px;
    }

    &-count {
      color: rgba(62, 60, 56, 0.4);
      font-size: 14px;
      font-weight: 500;
      line-height: 100%;
    }

    &--one-size {
      .filters__value-text {
        white-space: nowrap;
        width: fit-content;
      }
    }

    &.selected {
      background-color: $cultured;
      border-color: $grey3;
    }

    .disabled {
      .text {
        color: rgba(62, 60, 56, 0.4);
        text-decoration: none;
      }
    }

    @media (min-width: $media-breakpoint-desktop) {
      &.selected {
        background-color: transparent;

        .filters__value-text {
          color: $grey3;
        }
      }
    }
  }

  &__filter {
    border-color: $border-grey;
    border-width: 1px;

    &--selected-filters {
      display: none;

      @media (min-width: $bp-large) {
        display: flex;
        flex-wrap: wrap;
        background-color: $white;
        border: 1px solid $border-grey;
        padding-left: 16px;
        padding-right: 16px;
        font-weight: 500;
        line-height: 100%;
      }

      .card-header {
        padding: 20px 0 16px 0;
        background-color: $white;
      }

      .clear-all {
        margin-top: 20px;
        line-height: 110%;
        color: $grey3;
        font-weight: 400;
        font-size: 14px;
        margin-right: 16px;

        .filters__reset-link {
          color: $grey3;
        }
      }

      .values {
        margin-bottom: 0;
        display: flex;
        flex-wrap: wrap;
      }

      .card-body {
        padding-bottom: 24px;
      }
    }

    &__applied {
      background-color: $white;
      font-size: 15px;
      font-weight: 500px;
      border-radius: 57px;
      border: 1px solid $border-grey;
      margin-bottom: 10px;
      padding: 12px 16px !important;
      cursor: pointer;

      @media (min-width: $media-breakpoint-desktop) {
        padding: 12px 11px 12px 16px !important;
      }

      &__text {
        line-height: 16.5px;
      }

      &__remove__icon {
        &::before {
          content: url('../../../../static/default/images//filter__applied__remove_mobile.svg');
          position: relative;
          top: 2px;
          font-size: 13px;
        }
      }
    }

    &--size {
      .values {
        display: flex;
        flex-wrap: wrap;

        @include media-breakpoint-down(md) {
          margin-bottom: 16px;
          width: 100%;
        }
      }
    }
  }

  &__footer {
    box-shadow: none;
    border-top: 1px solid rgba(62, 60, 56, 0.3);
    .filters__reset {
      flex-direction: column;
      padding-bottom: 0 !important;

      div {
        flex: 0 0 100%;
        max-width: 100%;
      }

      .filters__close-link {
        @include btn-primary-small();
        text-transform: none !important;
      }

      .reset {
        @include btn-secondary-is();
        text-transform: none !important;
      }

      .reset,
      .filters__close-link {
        margin-bottom: 1.5rem;
        justify-content: center;
        display: flex;
        align-items: center;
      }

      @include media-breakpoint-down(lg) {
        flex-direction: row;

        div {
          flex: 0 0 50%;
          max-width: 50%;
          padding-right: 1rem;
          padding-left: 1rem;
        }
      }

      @media (min-width: $media-breakpoint-desktop) {
        flex-direction: row;

        div {
          flex: 0 0 50%;
          max-width: 50%;
        }
      }
    }

    .filters__result-count div {
      padding-top: 1rem !important;
    }

    .results-count {
      &--mobile {
        color: $grey3;
        font-weight: 700;
        font-size: 15px;
      }
    }
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &__input[type=number] {
    border-color: $grey3;
    margin-right: 2rem;
    width: 6.5rem;
  }

  &__form--price {
    .separator {
      display: none !important;
    }

    &__symbol {
      color: $grey2;
      font-weight: 700;
      font-size: 20px;
    }

    input[type=number] {
      &::placeholder,
      &[placeholder] {
        color: $grey2;
        font-weight: 700;
        font-size: 20px;
      }
    }
  }
}

.filters__filter:first-child:not(.filters__filter--selected-filters) {
  @media (min-width: $bp-large) {
    border-top: 1px solid rgba(62, 60, 56, 0.2);
  }
}
